import React from "react";
import { Text, TextInput, View } from "react-native";
import { useField } from "formik";
import _ from "lodash";
import styles from "../../styles/Form";
import Label from "./Label";

export default function TextField({ label, field, flex = 1, multiline = false }: { label: string, field: string, flex?: number, multiline?: boolean }) {
  // const { values, handleChange, errors } = useFormikContext()
  const [formikField, meta, helpers] = useField(field);

  const handleChange = _.debounce((val) => {
    helpers.setValue(val);
    helpers.setTouched(true, true);
  }, 250);


  return (
    <View style={[styles.column, { flex, zIndex: 0 }]}>
      <Label text={label} />
      <TextInput
        style={styles.input}
        defaultValue={formikField.value}
        onChangeText={handleChange}
        multiline={multiline}
      />
      {meta.touched && meta.error ? (
        <Text style={styles.error}>{meta.error}</Text>
      ) : null}
      {/* { _.get(errors, field)  ? <Text style={styles.error}>{_.get(errors, field)}</Text> : null} */}
    </View>
  );
}
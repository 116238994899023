import React from "react";
import { WorkOrderFieldValues } from "../types/WorkOrder";
import Index from "../components/WorkOrderForm";

export default function NewWorkOrderScreen()  {
  const workOrder: WorkOrderFieldValues = {
    identifier: "",
    name: "",
    assigneeID: null,
    assigneeName: "",
    contact: "",
    narrative: "",
    notes: "",
    location: "",
    jobID: "",
    startAt: new Date(),
    status: "upcoming",
    itemGroups: [
      {
        id: "default-group",
        name: "",
        items: [
          {
            id: "default-item",
            identifier: "",
            name: "",
            expected: 0,
            actual: 0,
            unit: "",
          },
        ],
      },
    ],
  }

  return (
    <Index workOrder={workOrder} />
  )
}

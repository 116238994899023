import React          from "react";
import PropTypes      from "prop-types";
import { View, Text } from "react-native";
import ItemGroupTable from "./ItemGroupTable";

ItemGroupTables.propTypes = {
  itemGroups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string.isRequired,
        identifier: PropTypes.string,
        expected: PropTypes.number,
        actual: PropTypes.number,
        unit: PropTypes.string
      }).isRequired).isRequired
    }).isRequired
  ).isRequired
};

function ItemGroupTables({ itemGroups }) {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <View>
      {
        itemGroups &&
        itemGroups.length > 0 ?
        (<View>{
          itemGroups.map( group => <ItemGroupTable group={group} key={`group-container-${group.id}`}/>)
        }</View>) :
        (<Text>No items to show.</Text>)
      }
    </View>
  );
}

export default ItemGroupTables;
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Image } from "react-native-elements";
import React from "react";

const photoStyle = StyleSheet.create({
  photo: {
    width: 200,
    height: 200,
  },
  photoWrapper: {
    borderWidth: 1,
    borderColor: "#aaa",
    padding: 3,
    margin: 10,
    borderRadius: 4,
    elevation: 4,
    shadowRadius: 5,
    shadowOffset: {
      width: 5,
      height: 5,
    },
    shadowColor: "#000",
    shadowOpacity: 0.2,
  },
});

export function PhotoAlbumItem({ target, name, setHeroPhoto }) {
  return (
    <TouchableOpacity key={target} onPress={() => setHeroPhoto(target)}>
      <View style={photoStyle.photoWrapper}>
        <Image source={{ uri: target }} style={photoStyle.photo} />
      </View>
    </TouchableOpacity>
  );
}
import React from 'react'
import { View, Text } from "react-native";
import { useCollection } from "@nandorojo/swr-firestore";
import styles from '../../styles/Form'
import Label from "./Label";
import Picker from "./Picker";
import { UserContext } from "../../providers/UserProvider";
import { assertIsProfile } from "../../services/ProfileService";
import Job from "../../types/Job";

export default function SelectJobField() {
  const { profile } = React.useContext(UserContext)
  assertIsProfile(profile)

  const { data } = useCollection<Job>(`${profile.company.path}/jobs`)

  return (
    <View style={styles.row}>
      <View style={styles.column}>
        <Label text="Job" />
        <Picker data={data ?? []} field="job.id" />
      </View>
    </View>
  )
}
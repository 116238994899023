import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, TouchableOpacity, View } from "react-native";

const styles = StyleSheet.create({
  button: {
    padding: 10,
    borderRadius: 5,
    borderWidth: 2,
    flexDirection: "row",
    alignItems: "center",
  },
});

export default function OutlineButton({ color, children, onPress, style }) {
  return (
    <TouchableOpacity
      style={[styles.button, { ...style, borderColor: color }]}
      onPress={onPress}
    >
      {children}
    </TouchableOpacity>
  );
}

OutlineButton.defaultProps = {
  color: "indigo",
};

OutlineButton.propTypes = {
  color: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onPress: PropTypes.func.isRequired,
};

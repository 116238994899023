import React, { useContext } from "react";
import { Text, View, StyleSheet } from 'react-native';
import { useCollection } from "@nandorojo/swr-firestore";
import styles from '../../styles/Form'
import TextField from "./TextField";
import Label from "./Label";
import { UserContext } from "../../providers/UserProvider";
import { assertIsProfile } from "../../services/ProfileService";
import Picker from "./Picker";
import Profile from "../../types/Profile";
import DateInput from "../DateInput";

export default function DetailsFields() {
  const { profile } = useContext(UserContext)
  assertIsProfile(profile)
  const { data } = useCollection<Profile>('profiles',
    {
      where: ["companyID", "==", profile.companyID],
    })

  return (
    <View style={[styles.column, { flexDirection: 'column-reverse'}]}>
      <View style={styles.row}>
        <TextField
          label="Notes"
          field="workOrder.notes"
        />
      </View>
      <View style={styles.row}>
        <TextField
          label="Narrative"
          field="workOrder.narrative"
         />
      </View>
      <View style={styles.row}>
        <TextField
          label="Location"
          field="workOrder.location"
        />
        <TextField
          label="Contact"
          field="workOrder.contact"
          multiline
        />
      </View>
      <View style={styles.row}>
        <View style={styles.column}>
          <Label text="Assigned To" />
          { data ? (<Picker
            data={data}
            field="workOrder.assigneeID"
          />) : null}
        </View>
        <View style={styles.column}>
          <DateInput label="Start At" fieldKey="workOrder.startAt" />
        </View>
      </View>

    </View>
  )
}
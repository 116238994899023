// @ts-ignore
import { nanoid } from "nanoid/async/index.native";
import { fuego } from "@nandorojo/swr-firestore";
import Yup from "../config/Yup";
import Profile from "../types/Profile";
import { Status } from "../types/WorkOrder";
import { Query, WhereQuery } from "../types/Query";

type workOrderQueryProps = {
  active: boolean,
  profile: Profile,
  listen: boolean,
  archived: null | boolean
}
export function workOrderQuery({ active = true, profile, listen = true, archived = null }: workOrderQueryProps ): Query {
  const allowedStatuses: Status[] =
    active
      ? ['delayed', 'upcoming', 'in_progress']
      : ['complete']

  const where: WhereQuery = [
    ['status', 'in', allowedStatuses],
  ]

  if(profile.role === 'foreman') {
    where.push(['assigneeID', '==', profile.id])
  }

  if(archived !== null) {
    where.push(['archived', '==', archived])
  }

  return ({
    listen,
    where,
    parseDates: ['startAt'],
  })

  // switch (profile.role) {
  //   case "foreman":
  //     return {
  //       listen,
  //       where: [
  //         ['status', 'in', allowedStatuses],
  //         ['assigneeID', '==', profile.id],
  //       ],
  //       parseDates: ['startAt'],
  //     }
  //   default:
  //     return {
  //       listen,
  //       where: [
  //         // 'archived', '==', false,
  //         ['status', 'in', allowedStatuses],
  //         ['archived', '==', false],
  //       ],
  //       parseDates: ['startAt'],
  //     }
  // }


}
// @ts-ignore
export const streamActiveWorkOrders = (profile, observer) => {
  console.warn('STREAM ACTIVE WORK ORDERS IS DEPRECATED.')
  // console.log('user is foreman? ', profile.role === 'foreman')
  return profile.role === "foreman" ?
    profile.company
      .collection("work_orders")
      .where("status", "in", ["in_progress", "delayed", "upcoming"])
      .where("assignee", "==", profile.ref)
      .orderBy("startAt")
      .onSnapshot(observer)
    : profile.company
      .collection("work_orders")
      .where("status", "in", ["in_progress", "delayed", "upcoming"])
      .orderBy("startAt")
      .onSnapshot(observer);
};

// @ts-ignore
export const streamCompletedWorkOrders = ({ profile, setWorkOrders }) => {
  console.warn('STREAM COMPLETED WORK ORDERS IS DEPRECATED')
  return profile.role === "foreman" ?
    profile.company
      .collection("work_orders")
      .where("status", "==", "complete")
      .where("assignee", "==", profile.ref)
      .onSnapshot({
        next: (querySnapshot) => {
          const updatedWorkOrders = querySnapshot.docs.map((doc) => ({
            documentID: doc.id,
            data: doc.data(),
          }));
          setWorkOrders(updatedWorkOrders);
        },
      })
    : profile.company
      .collection("work_orders")
      .where("status", "==", "complete")
      .onSnapshot({
        next: (querySnapshot) => {
          const updatedWorkOrders = querySnapshot.docs.map((doc) => ({
            documentID: doc.id,
            data: doc.data(),
          }));
          setWorkOrders(updatedWorkOrders);
        },
      });
};
// @ts-ignore
export const streamWorkOrder = ({ profile, workOrderID, setWorkOrder }) => {
  console.warn('StreamWorkOrder is deprecated.')
  // @ts-ignore
  return profile.company
    .collection("work_orders")
    .doc(workOrderID)
    .onSnapshot((snap) => {
      setWorkOrder(snap.data());
    });
};

export const SubmissionSchema = Yup.object().shape({
  workOrderID: Yup.string().required(),
  quantities: Yup.array().of(
    Yup.object()
      .shape({
        identifier: Yup.string(),
        name: Yup.string(),
        quantity: Yup.number(),
        unit: Yup.string(),
      })
      .uniqueProperty("name", "Names must be unique."),
  ),
  status: Yup.string().oneOf([
    "in_progress",
    "delayed",
    "upcoming",
    "complete",
  ]),
});

export const createSubmission = ({ profile, data }) => {
  // const itemGroups = data.item.map(
  //   ({ identifier, name, quantity, unit }) => ({
  //     identifier,
  //     name,
  //     quantity: Number.parseFloat(quantity) || 0,
  //     unit,
  //   })
  // );
  const subData = {
    itemGroups: data.itemGroups,
    workOrderID: data.workOrderID,
    submittedBy: fuego.db.doc(`/profiles/${profile.id}`),
    status: data.status,
  };

  console.log('make submission: ', subData)

  try {
    profile.company
      .collection("work_orders")
      .doc(data.workOrderID)
      .collection("submissions")
      .add(subData);
  } catch (e) {
    console.log("FATAL ERROR RECORDING SUBMISSION: ", e);
  }
};

export const createDocument = async ({ profile, workOrderID, file, fileFetches, setFileFetches }: { profile: Profile, workOrderID: string, file: any, fileFetches: number, setFileFetches: any}) => {
  const ref = fuego.storage()
    .ref()
    .child(
      `${profile.company.path}/work_orders/${workOrderID}/documents/${file.name}`,
    );

  const blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    // eslint-disable-next-line func-names
    xhr.onload = function() {
      resolve(xhr.response);
    };
    // eslint-disable-next-line func-names
    xhr.onerror = function(e) {
      reject(new TypeError(`Network request failed. ${e}`));
    };

    xhr.responseType = "blob";
    xhr.open("GET", file.uri, true);
    xhr.send(null);
  });

  const snapshot = await ref.put(blob);
  setFileFetches(fileFetches + 1);
  return snapshot.ref.getDownloadURL();
};

export const createPhoto = async ({ profile, workOrderID, uri, exif, fileFetches, setFileFetches }: { profile: Profile, workOrderID: string, uri: string, exif: string | undefined, fileFetches: number, setFileFetches: any }): void => {
  const id = await nanoid();
  const ref = fuego.storage()
    .ref()
    .child(`${profile.company.path}/work_orders/${workOrderID}/photos/${id}`);
  const blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      resolve(xhr.response);
    };
    xhr.onerror = e => {
      reject(new TypeError(`Network request failed. ${e}`));
    };

    xhr.responseType = "blob";
    xhr.open("GET", uri, true);
    xhr.send(null);
  });

  // @ts-ignore
  const snapshot = await ref.put(blob);
  setFileFetches(fileFetches + 1)
  // blob.close();

  return snapshot.ref.getDownloadURL();
};

export const workOrderDocuments = ({ profile, workOrderID }: { profile: Profile, workOrderID: string}) => {
  const ref = fuego.storage()
    .ref()
    .child(`${profile.company.path}/work_orders/${workOrderID}/documents`);
  return ref.listAll();
};

export const workOrderPhotos = ({ profile, workOrderID }: { profile: Profile, workOrderID: string}) => {
  const ref = fuego.storage()
    .ref()
    .child(`${profile.company.path}/work_orders/${workOrderID}/photos`);
  return ref.listAll();
};

export const streamWorkOrderSubmissions = ({ profile, workOrderID, setSubmissions }) => {
  return profile
    .company
    .collection("work_orders")
    .doc(workOrderID)
    .collection("submissions")
    .onSnapshot(snapshot => {
      setSubmissions(snapshot.docs);
    });
};
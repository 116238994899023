import React from "react";
import { Text } from "react-native";
import { useDocument } from "@nandorojo/swr-firestore";
import { UserContext } from "../providers/UserProvider";
import { assertIsProfile } from "../services/ProfileService";
import WorkOrder from "../types/WorkOrder";
import WorkOrderForm from "../components/WorkOrderForm";

// @ts-ignore
export default function EditWorkOrderScreen({ route }) {
  const { workOrderID } = route.params;

  const { profile } = React.useContext(UserContext);
  assertIsProfile(profile);
  const {
    data,
    error,
  } = useDocument<WorkOrder>(
    `${profile.company.path}/work_orders/${workOrderID}`,
    {
      parseDates: ['startAt'],
      ignoreFirestoreDocumentSnapshotField: true,
    });

  console.log('EDIT WO SCREEN - data: ', data)

  if (error) return (
    <Text>An error occurred: {error}</Text>
  );
  if (!data || data.startAt.seconds) return (<Text>Loading...</Text>);
  return (
    <WorkOrderForm workOrder={data} />
  );
}
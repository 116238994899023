import React                                         from "react";
import { ActivityIndicator, StyleSheet, Text, View } from "react-native";
import { AntDesign }                                 from "@expo/vector-icons";
import OutlineButton                                 from "../components/OutlineButton";
import RaisedButton                                  from "../components/RaisedButton";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  message: {
    fontSize: 22,
  },
});

function LoadingScreen() {
  return <View style={styles.container}>
     <ActivityIndicator size="large" color="#4b0082" />
     <Text>Loading...</Text>
    
  </View>
}

export default LoadingScreen;

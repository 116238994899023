// import { Linking } from "expo";
import * as Linking from "expo-linking";

const prefix = Linking.makeUrl("/");

const state = {
  prefixes: [prefix],
  screens: {
    WorkOrders: {
      initialRouteName: "NewWorkOrder",
      screens: {
        List: "foo",
        NewWorkOrder: "/workorders/new",
        Show: "/WorkOrders/Show/:workOrderID",
      },
    },
    Clients: {
      Show: {
        path: "client/:clientID",
      },
    },
  },
  // routes: [
  //   {
  //     name: "Home",
  //   },
  //   {
  //     name: "Details",
  //   },

  // ],
};

export default state;

import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    alignItems: "flex-start",
    alignSelf: "stretch",
    backgroundColor: "white",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
  },
  hero: {
    fontSize: 36,
    fontWeight: "bold",
  },
  row: {
    flexDirection: "row",
  },
  box: {
    flex: 1,
    width: "48%",
    maxWidth: 300,
  },
});

import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { ClientPropTypes, streamClient } from "../services/ClientService";
import { UserContext }                   from "../providers/UserProvider";

const styles = StyleSheet.create({
  title: {
    fontWeight: "600",
    fontSize: 24,
  },
  container: {
    flex: 1,
    backgroundColor: "white",
    padding: 5,
  },
});

function ShowClient({ profile, clientID }) {
  const [client, setClient] = React.useState({});

  React.useEffect(() => {
    const unsubscribe = streamClient({
      profile,
      clientID,
      observer: (doc) => setClient(doc.data()),
    });
    return unsubscribe;
  }, [profile, setClient]);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{client.name}</Text>
      <Text>Work Orders:</Text>
    </View>
  );
}

export default function ShowClientScreen({ route, navigation }) {
  const { clientID } = route.params;

  return (
    <UserContext.Consumer>
      {({ profile }) => <ShowClient profile={profile} clientID={clientID} />}
    </UserContext.Consumer>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { Platform, ScrollView, Text, TouchableOpacity, View } from "react-native";
import { Button, Image, ThemeContext } from "react-native-elements";
import * as DocumentPicker from "expo-document-picker";
import * as ImagePicker from "expo-image-picker";
import { FontAwesome, MaterialIcons } from "@expo/vector-icons";
import * as Linking from "expo-linking";
import Autolink from "react-native-autolink";
import { StackNavigationProp } from "@react-navigation/stack";
import { RouteProp } from "@react-navigation/native";
import { fuego, useDocument } from "@nandorojo/swr-firestore";
import { UserContext } from "../providers/UserProvider";
import { createDocument, createPhoto, workOrderDocuments, workOrderPhotos } from "../services/WorkOrderService";
import { statusStyle } from "../styles/Card";
import OutlineButton from "../components/OutlineButton";
import RaisedButton from "../components/RaisedButton";
import ItemGroupTables from "../components/ItemGroupTables";
// eslint-disable-next-line import/no-cycle
import { WorkOrderStackParamList } from "../navigation/WorkOrderTab";
import Profile from "../types/Profile";
import WorkOrder from "../types/WorkOrder";
import { PhotoAlbumItem } from "../components/WorkOrder/PhotoAlbumItem";
import DocumentListItem from "../components/WorkOrder/DocumentListItem";
import styles from "../styles/WorkOrder";
import { StorageFile } from "../types/StorageFile";
import { canDeletePhoto } from "../services/ProfileService";
import { deleteFileAtPath } from "../services/StorageService";

type ShowWorkOrderProps = {
  navigation: StackNavigationProp<WorkOrderStackParamList, "Show">,
  profile: Profile,
  workOrderID: string
}

function ShowWorkOrder({ profile, workOrderID, navigation }: ShowWorkOrderProps) {
  const [documents, setDocuments] = useState<StorageFile[]>([]);
  const [photos, setPhotos] = useState<StorageFile[]>([]);
  const [heroPhoto, setHeroPhoto] = useState<StorageFile | null>(null);
  const [cameraPermissionGranted, setCameraPermissionGranted] = useState(false);
  const [fileFetches, setFileFetches] = useState<number>(0);
  const [showDeleteHero, setShowDeleteHero] = useState(false);

  const { theme } = useContext(ThemeContext);

  const {
    data,
    update,
  } = useDocument<WorkOrder>(`${profile.company.path}/work_orders/${workOrderID}`,
    {
      listen: true,
      parseDates: ["startAt"],
    },
  );


  useEffect(() => {
    Platform.OS !== "web"
      ? ImagePicker.requestCameraPermissionsAsync()
        .then(({ status }) => setCameraPermissionGranted(status === "granted"))
      : setCameraPermissionGranted(true);
    workOrderDocuments({ profile, workOrderID }).then((res) => {
        setDocuments([]);
        res.items.forEach((element) => {
          element.getDownloadURL().then((url) => {
            // @ts-ignore
            setDocuments((prevDocuments) => [
              ...prevDocuments,
              { name: element.name, downloadURL: url, path: element.fullPath },
            ]);
          });
        });
      },
    );
    workOrderPhotos({ profile, workOrderID }).then((res) => {
        setPhotos([]);
        res.items.forEach((element) => {
          element.getDownloadURL().then((url) => {
            // @ts-ignore
            setPhotos((prevPhotos) => {

              return [...prevPhotos, { name: element.name, downloadURL: url }];
            });
          });
        });
      },
    );
  }, [fileFetches]);

  if (!data) return (<Text>Fetching work order...</Text>);

  const {
    identifier,
    name,
    status,
    assigneeName,
    location,
    clientName,
    contact,
    narrative,
    notes,
    startAt,
    itemGroups,
    archived,
  } = data;

  const locationTarget = `https://www.google.com/maps/search/?api=1&query=${encodeURI(
    location,
  )}`;

  // @ts-ignore
  return (
    <ScrollView style={styles.container}>
      <View style={[styles.row, { justifyContent: 'space-between'}]}>
        <View style={[styles.row, { margin: 0, padding: 0 }]}>
          <Text style={styles.hero}>#{identifier} </Text>
          <Text style={styles.title}>- {name}</Text>
        </View>
        {
          archived ? (
            <Button
              type="solid"
              title="Unarchive"
              onPress={() => update({ archived: false })}
              icon={<MaterialIcons name="unarchive" color="white" size={24} />}
              />
          ) : (
            <Button
              type="outline"
              title="Archive"
              onPress={() => update({ archived: true })}
              icon={<MaterialIcons name="archive" color="indigo" size={24} />}
              />
          )
        }
      </View>
      <View style={styles.row}>
        <Text style={statusStyle(status)}>{status} </Text>
        <Text style={styles.body}>
          by {assigneeName} for {clientName}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.body}>
          { /* need to useDocument */}
          {startAt?.toLocaleString([], {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}        </Text>
      </View>
      <View style={styles.row}>
        <View style={styles.box}>
          <Text style={styles.label}>Location:</Text>
          <TouchableOpacity onPress={() => Linking.openURL(locationTarget)}>
            <Text style={[styles.anchor, { width: "100%" }]}>{location}</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.box}>
          <Text style={styles.label}>Contact:</Text>
          <Autolink style={[styles.body, { width: "100%" }]} linkStyle={styles.anchor} text={contact} />
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.box}>
          <Text style={styles.label}>Narrative:</Text>
          <Autolink style={[styles.body, { width: "100%" }]} text={narrative} />
        </View>
        <View style={styles.box}>
          <Text style={styles.label}>Notes:</Text>
          <Autolink style={[styles.body, { width: "100%" }]} text={notes} />
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.box}>
          <View style={styles.row}>
            <Text style={styles.title}>Documents</Text>
          </View>
          {documents ? (
            documents.map((doc) => (
              <DocumentListItem
                document={doc}
                key={doc.downloadURL}
              />
            ))
          ) : (
            <View style={styles.row}>
              <Text>No documents found.</Text>
            </View>
          )}
          <View style={styles.row}>
            <RaisedButton
              onPress={() =>
                DocumentPicker.getDocumentAsync().then((file) =>
                  createDocument({ profile, workOrderID, file, fileFetches, setFileFetches }),
                )
              }
            >
              <FontAwesome name="file-pdf-o" size={24} color="white" />
              <Text style={[styles.body, { color: "white", margin: 1, height: 28 }]}>
                {" "}
                Attach Document
              </Text>
            </RaisedButton>
          </View>
        </View>
      </View>
      {/* <View style={styles.divider} /> */}
      <View style={styles.row}>
        <View style={styles.box}>
          <Text style={styles.title}>Summary</Text>
          <ItemGroupTables itemGroups={itemGroups} />
          <View style={[styles.row, { justifyContent: "space-between", width: "100%" }]}>
            <View style={{ width: 300 }}>
              <Button
                type="solid"
                title=" Review Submissions"
                icon={<FontAwesome name="files-o" size={24} color="white" />}
                onPress={() => navigation.navigate("Submissions", { workOrderID })}
              />
            </View>
            <View style={{ width: "33%" }}>
              <Button
                type="outline"
                title=" Record Submission"
                icon={<FontAwesome name="file-text" size={24} color={theme.colors?.primary} />}
                onPress={() => navigation.navigate("NewSubmission", { workOrderID })}
              />
            </View>

          </View>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.box}>
          <View style={styles.row}>
            <Text style={styles.title}>Photos</Text>
          </View>
          {heroPhoto ? (
            <>
              <View style={styles.heroPhotoWrapper}>
                {/* @ts-ignore */}
                <Image source={{ uri: heroPhoto }} style={styles.heroPhoto} resizeMethod="scale" resizeMode="contain" />
              </View>
              {canDeletePhoto(profile) ? (
                <>
                  <View style={styles.row}>
                    <Button
                      type="outline"
                      title=" Delete"
                      icon={<FontAwesome name="trash" size={24} color="indigo" />}
                      onPress={() => setShowDeleteHero(!showDeleteHero)}
                    />
                  </View>
                  {
                    showDeleteHero ? (
                      <>
                        <View style={styles.row}>
                          <Text style={styles.body}>
                            Really delete this photo? This cannot be undone.
                          </Text>
                        </View>
                        <View style={styles.row}>
                          <Button
                            type="outline"
                            title="Cancel"
                            onPress={() => setShowDeleteHero(false)}
                          />
                          <Text>{" "}</Text>
                          <Button
                            type="solid"
                            title="Permanently Delete"
                            onPress={() => deleteFileAtPath(fuego.storage().refFromURL(heroPhoto).fullPath)}
                          />
                        </View>
                      </>

                    ) : null
                  }
                </>

              ) : null}

            </>
          ) : null}
          {photos.length !== 0 ? (
            <View
              style={[
                styles.row,
                { justifyContent: "space-around", width: "100%" },
              ]}
            >
              {photos.map((photo) =>
                PhotoAlbumItem({
                  target: photo.downloadURL,
                  name: photo.name,
                  setHeroPhoto,
                }),
              )}
            </View>
          ) : (
            <View style={styles.row}>
              <Text style={styles.body}>
                No photos added for this work order yet.{" "}
              </Text>
            </View>
          )}
          <View style={[styles.row, { justifyContent: "space-between" }]}>
            <RaisedButton
              onPress={() =>
                ImagePicker.launchImageLibraryAsync({ exif: true }).then(
                  (res) => {
                    if (!res.cancelled) {
                      createPhoto({
                        profile,
                        workOrderID,
                        uri: res.uri,
                        exif: res.exif,
                        fileFetches,
                        setFileFetches,
                      });
                    }
                  },
                )
              }
            >
              <Text style={[styles.body, { color: "white" }]}>
                <FontAwesome name="image" size={24} color="white" /> Select
                Photo
              </Text>
            </RaisedButton>
            {cameraPermissionGranted ? (<OutlineButton
              style={{}}
              onPress={async () =>
                ImagePicker.launchCameraAsync({ exif: true }).then((res) => {
                  if (!res.cancelled) {
                    createPhoto({
                      profile,
                      workOrderID,
                      uri: res.uri,
                      exif: res.exif,
                      fileFetches,
                      setFileFetches,
                    });
                  }
                })
              }
            >
              <FontAwesome name="camera" size={24} color="indigo" />
              <Text style={[styles.body, { color: "indigo" }]}>
                {" "}
                Take Photo
              </Text>
            </OutlineButton>) : null}
          </View>
        </View>
      </View>
    </ScrollView>
  );

}

type Props = {
  navigation: StackNavigationProp<WorkOrderStackParamList, "Show">,
  route: RouteProp<WorkOrderStackParamList, "Show">
}
export default function ShowWorkOrderScreen({ route, navigation }: Props) {
  const { workOrderID } = route.params;

  return (
    <UserContext.Consumer>
      {({ profile }) => (
        (profile ? (<ShowWorkOrder
            profile={profile}
            workOrderID={workOrderID}
            navigation={navigation}
          />) : (<Text>Fetching profile.</Text>)
        )

      )}
    </UserContext.Consumer>
  );
}



/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import { fuego, getDocument } from "@nandorojo/swr-firestore";
import * as firebase from "firebase";
import Profile from "../types/Profile";

interface UserContextInterface {
  user: firebase.User | null,
  profile: Profile | null,
  authStateFetched: boolean
}

export const UserContext = React.createContext<UserContextInterface>({
  user: null,
  profile: null,
  authStateFetched: false,
});

class UserProvider extends Component {
  state = {
    user: null,
    profile: null,
    authStateFetched: false,
  };
  
  componentDidMount = () => {
    fuego.auth().onAuthStateChanged((userAuth) => {
      const { profile: currentProfile } = this.state;

      // eslint-disable-next-line react/no-unused-state
      this.setState({ user: userAuth, authStateFetched: true });
      if (userAuth && currentProfile == null) {
        getDocument(`profiles/${userAuth.uid}`).then(r => {
          this.setState({ profile: r})
        })

      } else if (!userAuth) {
        this.setState({ profile: null });
      }
    });
  };

  render() {
    const { children } = this.props;

    return (
      <UserContext.Provider value={this.state}>
        {children}
      </UserContext.Provider>
    );
  }
}


export default UserProvider;

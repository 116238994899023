import * as Yup from "yup";
import PropTypes from "prop-types";

export const ClientPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
}).isRequired;

export const ClientSchema = Yup.object().shape({
  name: Yup.string().required("Must provide a name."),
});

export function createClientForProfile(profile, values) {
  return profile.company.collection("clients").add(values);
}

export function streamClientsForProfile(profile, observer) {
  // console.log("streaming all clients!");
  return profile.company.collection("clients").onSnapshot(observer);
}

export function streamClient({ profile, clientID, observer }) {
  // console.log("streaming client: ", clientID);
  return profile.company
    .collection("clients")
    .doc(clientID)
    .onSnapshot(observer);
}

import React          from 'react'
import { View, Text } from 'react-native'
import { ButtonGroup} from "react-native-elements";
import PropTypes      from "prop-types";
import styles from '../styles/Form'
export default function StatusSelector(props) {
  const {
    statusLabels,
    selectedStatus,
    setSelectedStatus,
    setFieldValue,
    statuses,
  } = props
  
  return (
    <View styles={styles.row}>
      <Text style={styles.label}>Status</Text>
    <ButtonGroup
    buttons={statusLabels}
    selectedIndex={selectedStatus}
    onPress={(selectedIndex) => {
      setSelectedStatus(selectedIndex);
      setFieldValue("status", statuses[selectedIndex]);
    }}
    containerStyle={{
      flex: 1,
      // borderColor: "green",
      // borderWidth: 2,
      // width: "100%",
    }}
    buttonContainerStyle={
      {
        borderWidth: 0,
      }
    }
    textStyle={{
      fontSize: 17,
      padding: 5,
      margin: 5,
      // width: 100,
      // alignSelf: "center",
    }}
  />
    </View>
  )
}

StatusSelector.propTypes = {
  statusLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedStatus: PropTypes.number.isRequired,
  setSelectedStatus: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.string).isRequired
}
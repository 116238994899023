import React                                            from "react";
import { View, Text, StyleSheet, FlatList, Dimensions } from "react-native";
import { useCollection }                                from "@nandorojo/swr-firestore";
import { MaterialIcons }                                from "@expo/vector-icons";
import { UserContext }                                  from "../providers/UserProvider";
import { streamCompletedWorkOrders, workOrderQuery }    from "../services/WorkOrderService";
import WorkOrderCard                                    from "../components/WorkOrderCard";
import OutlineButton                                    from "../components/OutlineButton";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    paddingTop: 10,
    paddingLeft: 10,
  },
});
export default function CompletedWorkOrdersScreen({ navigation }) {
  return (
    <UserContext.Consumer>
      {({ profile }) => (
        <CompletedWorkOrderList profile={profile} navigation={navigation} />
      )}
    </UserContext.Consumer>
  );
}

function CompletedWorkOrderList({ profile, navigation }) {
  // const [workOrders, setWorkOrders] = React.useState([]);
  
  const [archive, setArchive] = React.useState(false)
  const renderItem = ({ item }) => (
    <WorkOrderCard document={item} navigation={navigation} />
  );
  
  const { data } = useCollection(
    `${profile.company.path}/work_orders`,
    workOrderQuery({ active: false, profile, listen: true, archived: archive}),
    {
      parseDates: ['startAt'],
    })

  const numColumns = Dimensions.get("window").width > 1200 ? 2 : 1;
  return (
    <View style={styles.container}>
      <View style={{ width: 200}}>
        <OutlineButton
          style={{ marginLeft: 10 }}
          onPress={() => setArchive(!archive)}
        >
          <MaterialIcons size={24} color="indigo" name="archive" />
          <Text style={{ color: "indigo", fontSize: 17, marginRight: 10 }}>
            {" "}
            { archive ? ("Hide Archived") : ("Show Archived")}
          </Text>
        </OutlineButton>
      </View>
      <FlatList
        data={data}
        renderItem={renderItem}
        numColumns={numColumns}
        keyExtractor={(item) => item.id}
      />
    </View>
  );
}

import React from "react";
import { Text, View, TextInput, Button } from "react-native";
import { Formik }      from "formik";
import { UserContext } from "../providers/UserProvider";
import {
  ClientSchema,
  createClientForProfile,
}                      from "../services/ClientService";
import styles from "../styles/Form";

// const styles = StyleSheet.create({})
// eslint-disable-next-line react/prefer-stateless-function
class NewClientScreen extends React.Component {
  render() {
    const { navigation } = this.props;
    const { profile } = this.context;

    return (
      <View style={styles.container}>
        <Text style={styles.title}>New Client</Text>
        <Formik
          initialValues={{ name: "" }}
          onSubmit={(values) => {
            createClientForProfile(profile, values).then(() =>
              navigation.navigate("List")
            );
          }}
          validationSchema={ClientSchema}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
          }) => (
            <View style={styles.formContainer}>
              <Text style={styles.label}>Name</Text>
              <View style={styles.inputContainer}>
                <TextInput
                  style={styles.input}
                  onChangeText={handleChange("name")}
                  onBlur={handleBlur("name")}
                  value={values.email}
                />
              </View>
              {errors.name && touched.name ? (
                <Text style={styles.error}>{errors.name}</Text>
              ) : null}
              <Button onPress={handleSubmit} title="Submit" />
            </View>
          )}
        </Formik>
      </View>
    );
  }
}
NewClientScreen.contextType = UserContext;

export default NewClientScreen;

/* eslint-disable react/jsx-filename-extension */
// import { StatusBar } from "expo-status-bar";
import React from "react";
// import { StyleSheet } from "react-native";
// import { NavigationContainer } from "@react-navigation/native";
// import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import { Fuego, FuegoProvider } from "@nandorojo/swr-firestore";
import { YellowBox } from "react-native";
import { ThemeProvider } from "react-native-elements";

import Navigation from "./navigation/index";
// import { auth, firestore } from "./config/fb";
// import UserProvider from "./providers/UserProvider";
import UserProvider from "./providers/UserProvider";
import { theme } from "./config/variables";

const firebaseConfig = {
  apiKey: "AIzaSyAyLM9DxGUjv-AjUgPx8UrQG6kwm-E8G-I",
  authDomain: "ground-control-pro.firebaseapp.com",
  databaseURL: "https://ground-control-pro.firebaseio.com",
  projectId: "ground-control-pro",
  storageBucket: "ground-control-pro.appspot.com",
  messagingSenderId: "973718812238",
  appId: "1:973718812238:web:95c7fb9683c1679dc5351f",
  measurementId: "G-3RQ9CNXGRG",
};

const fuego = new Fuego(firebaseConfig);
// fuego.db.settings({
//     host: "65c5ccee03a4.ngrok.io",
//
//   ssl: true,
// })

// console.log("fuego storage: ", fuego.storage());

// This fixes the yellow box and the console log. It even fixes it for Expo.

// Simply place the following script at the beginning of your codebase.
YellowBox.ignoreWarnings(["Setting a timer"]);


export default function App() {
  return (
    <FuegoProvider fuego={fuego}>
      <UserProvider>
        <ThemeProvider theme={theme}>
          <Navigation />
        </ThemeProvider>
      </UserProvider>
    </FuegoProvider>

  );
}


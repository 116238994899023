import React                                from 'react';
import { Text, View, FlatList, Dimensions, TouchableOpacity } from "react-native";
import { useCollection }                    from "@nandorojo/swr-firestore";
import { useNavigation } from "@react-navigation/native";
import List            from "../styles/List";
import Card            from "../styles/Card";
import { UserContext } from "../providers/UserProvider";
import Profile                  from "../types/Profile";
import { assertIsProfile } from "../services/ProfileService";

export default function UsersListScreen() {
  const { profile } = React.useContext(UserContext)
  assertIsProfile(profile)
  const { data, error } = useCollection<Profile>('profiles', {
    listen: true,
    where: ["companyID", "==", profile.company.id],
  })

  // const renderProfile = ({ item }: { item: Profile }) => (
  //
  // )
  
  const numColumns = Dimensions.get("window").width > 1200 ? 2 : 1;
  
  if(error) return(<Text>An error occurred when fetching company users.</Text>)
  if(!data) return(<Text>Fetching company users...</Text>)

  const navigation = useNavigation()
  return (
    <View style={List.container}>
      <Text style={List.title}>
        All Users ({JSON.stringify(data.length)})
      </Text>
      <FlatList
        data={data}
        renderItem={({ item }) => (
          <TouchableOpacity onPress={() => navigation.navigate("Show", { userID: item.id })}>

          <View style={Card.card}>
            <Text style={Card.cardText}>
              {item.name} ({item.role})
            </Text>
          </View>
          </TouchableOpacity>
        )}
        keyExtractor={item => item.id}
        numColumns={numColumns}
      />
    </View>)
}
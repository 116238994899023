import React from "react";
import { Text, View } from "react-native";
import Select from "react-select";
import { useFormikContext } from "formik";
import _ from "lodash";
import styles from "../../styles/Form";


type Pickable = {
  name: string,
  id: string
}

export default function Picker({ data, field }: { data: Pickable[], field: string }) {

  const options = data.map((datum) => ({ value: datum.id, label: datum.name }));

  const { values, setFieldValue, errors, setFieldTouched } = useFormikContext();
  const currentValue: string | null = _.get(values, field);


  const currentIndex = options.findIndex((opt) => opt.value === currentValue);

  return (
    <View style={{ flex: 1, width: "100%" }}>
      <Select
        // @ts-ignore
        defaultValue={currentIndex !== -1 && options[currentIndex]}
        onChange={(res) => {
          setFieldValue(field, res?.value, false);
          setFieldTouched(field, true, false);
        }}
        options={options}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        isClearable
      />
      {_.get(errors, field) ? (<Text style={styles.error}>{_.get(errors, field)}</Text>) : null}
    </View>


  );
}
import {
  Text,
  TextInput,
  View,
  StyleSheet,
}                                       from "react-native";
import React                            from "react";
import _ from 'lodash'
import PropTypes                                  from "prop-types";
import { useFormikContext, FieldArray, useField } from "formik";
import { Button }                                 from "react-native-elements";
import formStyles                       from "../../styles/Form";
import LineItemInput  from "./LineItemInput";
import { createItem } from "../../services/WorkOrderFormService";

ItemGroup.propTypes = {
  group: PropTypes.shape({
    name: PropTypes.string,
    items: PropTypes.array.isRequired,
  }),
  index: PropTypes.number.isRequired,
  isSubmission: PropTypes.bool.isRequired,
};

export default function ItemGroup({ group, index, isSubmission }) {
  
  const {
    name,
    id,
  } = group;
  
  const { values } = useFormikContext()
  
  const itemGroup = isSubmission ? values.itemGroups[index] : values.workOrder.itemGroups[index]
  const nameBase = isSubmission ? `itemGroups.${index}` : `workOrder.itemGroups.${index}`
  
  const [formikField, meta, helpers] = useField(`${nameBase}.name`)
  
  const groupNameChangeText = _.debounce((val) => {
    helpers.setTouched(true, false)
    helpers.setValue(val, false)
  }, 250)
  
  return (
    <View style={GroupStyles.container}>
      <Text style={formStyles.label}>Group Name</Text>
      <TextInput
        style={formStyles.input}
        defaultValue={formikField.value}
        onChangeText={groupNameChangeText}
      />
      <FieldArray name={`${nameBase}.items`}>
        
        {(arrayHelpers) => (
          <>
          <View style={GroupStyles.items}>
            <View style={GroupStyles.row}>
              <Text style={[GroupStyles.id, formStyles.label]}>
                ID
              </Text>
              <Text style={[GroupStyles.name, formStyles.label]}>
                Name
              </Text>
              <Text style={[GroupStyles.expected, formStyles.label]}>
                { isSubmission ? 'Actual' : 'Expected'}
              </Text>
              <Text style={[GroupStyles.unit, formStyles.label]}>
                Unit
              </Text>
            </View>
              {
              itemGroup &&
                itemGroup.items &&
                itemGroup.items.length > 0 &&
                itemGroup.items.map((item, idx) => (
                <LineItemInput key={`lineItemInput-${item.id}`}groupIndex={index} itemIndex={idx} isSubmission={isSubmission} arrayHelpers={arrayHelpers} />
                ))
              }
          </View>
          <View>
             <Button
               type="outline"
               title="Add Item"
               onPress={() => createItem({ arrayHelpers})}
             />
            {/* <Text>{JSON.stringify(values.itemGroups[index])}</Text> */}
          </View>
          </>
        )}
      </FieldArray>
    </View>
  );
}

const GroupStyles = StyleSheet.create({
  container: {
    padding: 5,
    marginBottom: 5,
    // marginHorizontal: 5,
    borderWidth: 2,
    borderRadius: 5,
    borderColor: "#aaa",
    flex: 1,
    width: "100%",
    alignSelf: "stretch",
  },
  items: {
    flexDirection: 'column',
    marginBottom: 8,
  },
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
  },
  id: {
    flex: 1,
  },
  name: {
    flex:4,
  },
  expected: {
    flex: 2,
  },
  actual: {
    flex: 2,
  },
  unit: {
    flex: 1,
  },
});
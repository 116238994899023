import { Button, Text, TouchableOpacity, View, StyleSheet } from "react-native";
import moment                                   from "moment";
import _                                        from "lodash";
import React                                    from "react";
import { useNavigation } from '@react-navigation/native'


export default function WorkOrderAgendaItem({ item }) {
  const {
    startAt,
    name,
    assigneeName,
    clientName,
    identifier,
    status,
  } = item.data();
  
  const navigation = useNavigation()
  
  return (
    
          <TouchableOpacity
            onPress={() =>
              navigation.navigate("Show", { workOrderID: item.id })
            }
            style={styles.item}
            // testID={testIDs.agenda.ITEM}
          >
            <View>
              <Text style={styles.itemHourText}>
                {moment(startAt.toDate()).format("h:mma")}
              </Text>
              {/* <Text style={styles.itemDurationText}>{item.duration}</Text> */}
            </View>
            <View style={{ flexDirection: "column", paddingHorizontal: 5 }}>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.workOrderIdentifier}>#{identifier}</Text>
                <Text style={styles.workOrderTitle}> - {name}</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text>{_.startCase(status)} </Text>
                <Text>
                  by {assigneeName} for {clientName}
                </Text>
              </View>
            </View>
            {/* <View style={styles.itemButtonContainer}> */}
            {/*  <Button color="grey" title="Info" onPress={this.buttonPressed} /> */}
            {/* </View> */}
          </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  calendar: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  section: {
    backgroundColor: 'indigo',
    color: "#444",
    textTransform: "capitalize",
  },
  item: {
    padding: 20,
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderBottomColor: "lightgrey",
    flexDirection: "row",
  },
  itemHourText: {
    color: "#333",
    fontWeight: "500",
  },
  itemDurationText: {
    color: "grey",
    fontSize: 12,
    marginTop: 4,
    marginLeft: 4,
  },
  itemTitleText: {
    color: "black",
    marginLeft: 16,
    fontWeight: "bold",
    fontSize: 16,
  },
  itemButtonContainer: {
    flex: 1,
    alignItems: "flex-end",
  },
  emptyItem: {
    paddingLeft: 20,
    height: 52,
    justifyContent: "center",
    borderBottomWidth: 1,
    borderBottomColor: "lightgrey",
  },
  emptyItemText: {
    color: "lightgrey",
    fontSize: 14,
  },
  workOrderIdentifier: {
    fontWeight: "600",
  },
});
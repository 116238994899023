import React, { useContext } from "react";
import { FlatList, Platform, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useCollection } from "@nandorojo/swr-firestore";
import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import _ from "lodash";
import { UserContext } from "../providers/UserProvider";
import { workOrderQuery } from "../services/WorkOrderService";
// import styles from "../styles/List";
import { assertIsProfile } from "../services/ProfileService";
import LoadingScreen from "./LoadingScreen";
import WorkOrder from "../types/WorkOrder";
import OutlineButton from "../components/OutlineButton";
import RaisedButton from "../components/RaisedButton";
import { statusStyle } from "../styles/Card";
import FloatingActionButton from "../components/FloatingActionButton";

// function WorkOrderListScreen({ navigation }) {
//   // console.log("WorkOrderListScreen");
//   const [archive, setArchive] = React.useState(false)
//
//   return (
//     <View style={styles.container}>
//       <View style={[styles.row, { marginBottom: 10 }]}>
//         <OutlineButton
//           style={{ marginRight: 10 }}
//           onPress={() => navigation.navigate("Completed")}
//         >
//           <AntDesign size={24} color="indigo" name="inbox" />
//           <Text style={{ color: "indigo", fontSize: 17, marginRight: 10 }}>
//             {" "}
//             Completed Work Orders
//           </Text>
//         </OutlineButton>
//         <RaisedButton
//           style={{ marginRight: 10 }}
//           onPress={() => navigation.navigate("Calendar")}
//         >
//           <AntDesign size={24} color="white" name="calendar" />
//           <Text style={{ color: "white", fontSize: 17, marginRight: 10 }}>
//             {" "}
//             Calendar
//           </Text>
//         </RaisedButton>
//         <OutlineButton
//           style={{ marginLeft: 10 }}
//           onPress={() => setArchive(!archive)}
//         >
//           <MaterialIcons size={24} color="indigo" name="archive" />
//           <Text style={{ color: "indigo", fontSize: 17, marginRight: 10 }}>
//             {" "}
//             { archive ? ("Hide Archived") : ("Show Archived")}
//           </Text>
//         </OutlineButton>
//       </View>
//       <UserContext.Consumer>
//         {({ profile }) => (
//           <WorkOrderList profile={profile} navigation={navigation} archive={archive} />
//         )}
//       </UserContext.Consumer>
//
//       {Platform.OS === "web" ? <FloatingActionButton onPress={() => navigation.navigate("New")} /> : null}
//     </View>
//   );
// }
//
// function WorkOrderList({ profile, archive }) {
//   const { data, error } = useCollection(
//     `${profile.company.path}/work_orders`,
//     workOrderQuery({ profile, archived: archive }),
//   );
//
//   const renderWorkOrder = ({ item }) => (
//     <WorkOrderCard document={item} />
//   );
//
//   const numColumns = Math.floor(Dimensions.get("window").width / 1000) + 1;
//
//   if (error) return (<Text>{JSON.stringify(error)}</Text>);
//
//   return (
//     <FlatList
//       data={data}
//       renderItem={renderWorkOrder}
//       numColumns={numColumns}
//       keyExtractor={(item) => item.id}
//     />
//   );
// }
//

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    flex: 1,
    flexDirection: "column",
    padding: 10,
  },
  tableHeader: {
    flexDirection: "row",
  },
  theadLabel: {
    fontWeight: "bold",
    fontSize: 17,
  },
  trow: {
    flexDirection: "row",
    padding: 10,
    borderTopColor: '#999',
    borderTopWidth: 1,
  },
  cellText: {
    fontSize: 17,
  },
  row: {
    flexDirection: "row",
  },

});

type Column = {
  label: string,
  flex: number,
  property: string,
  accessor: string | ((workOrder: WorkOrder) => Element)
}

type SortDirection = "asc" | "desc"

function WorkOrderListScreen({ navigation }) {
  const [archive, setArchive] = React.useState(false);
  const [active, setActive] = React.useState(true)
  const [selectedColumn, setSelectedColumn] = React.useState("startAt")
  const [sortDirection, setSortDirection] = React.useState<SortDirection>('desc')
  const { profile } = useContext(UserContext);
  assertIsProfile(profile);
  const { data, error } = useCollection(
    `${profile.company.path}/work_orders`,
    workOrderQuery({ active, profile, listen: true, archived: archive }),
    {
      parseDates: ["startAt"],
    });
  // console.debug('WorkOrderListScreen: - data: ', data, 'error: ', error, ' query: ',  workOrderQuery({ active: true, profile, listen: true, archived: archive }), ' queried path: ', `${profile.company.path}/work_orders`)

  if (!data) {
    return <LoadingScreen />;
  }

  const workOrders = _.orderBy(data, selectedColumn, sortDirection )

  const columns = [
    {
      label: "ID",
      flex: 1,
      property: "identifier",
      accessor: (workOrder => <Text style={[styles.cellText, { fontWeight: "500" }]}>#{workOrder.identifier}</Text>),
    },
    { label: "Name", property: "name", flex: 4, accessor: "name" },
    { label: "Assigned To", property: "assigneeName", flex: 2, accessor: "assigneeName" },
    { label: "Client", property: "clientName", flex: 2, accessor: "clientName" },
    {
      label: "Date", property: "startAt", flex: 2, accessor: (workOrder) => <Text style={styles.cellText}>
        {workOrder.startAt.toLocaleString([], {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}</Text>,
    },
    { label: "Status", flex: 1, property: "status", accessor: (workOrder => <Text style={[styles.cellText, statusStyle(workOrder.status)]}>{workOrder.status.split("_").join(" ")}</Text>) },
  ];

  return (<View style={styles.container}>

    <View style={[styles.row, { marginBottom: 10 }]}>
      <OutlineButton
        style={{ marginRight: 10 }}
        onPress={() => setActive(!active)}
      >
        <AntDesign size={24} color="indigo" name="inbox" />
        <Text style={{ color: "indigo", fontSize: 17, marginRight: 10 }}>
          {" "}
          { active ? "Completed" : "Active"} Work Orders
        </Text>
      </OutlineButton>
      <RaisedButton
        style={{ marginRight: 10 }}
        onPress={() => navigation.navigate("Calendar")}
      >
        <AntDesign size={24} color="white" name="calendar" />
        <Text style={{ color: "white", fontSize: 17, marginRight: 10 }}>
          {" "}
          Calendar
        </Text>
      </RaisedButton>
      <OutlineButton
        style={{ marginLeft: 10 }}
        onPress={() => setArchive(!archive)}
      >
        <MaterialIcons size={24} color="indigo" name="archive" />
        <Text style={{ color: "indigo", fontSize: 17, marginRight: 10 }}>
          {" "}
          {archive ? ("Hide Archived") : ("Show Archived")}
        </Text>
      </OutlineButton>
    </View>
    <TableHeader columns={columns} selectedColumn={selectedColumn} sortDirection={sortDirection} setSelectedColumn={setSelectedColumn} setSortDirection={setSortDirection} />
    <FlatList data={workOrders}  keyExtractor={(item) => `table-row-${item.id}`} renderItem={({ item }) => {
      return <TableRow workOrder={item} columns={columns} />;
    }} />
    {Platform.OS === "web" ? <FloatingActionButton onPress={() => navigation.navigate("New")} /> : null}

  </View>);
}

function TableHeader({ columns, selectedColumn, sortDirection, setSelectedColumn, setSortDirection }: { columns: Column[], selectedColumn: string, sortDirection:  SortDirection, setSelectedColumn: (column: string) => void, setSortDirection: (direction: SortDirection) => void }) {

  return <View style={styles.tableHeader}>
    {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
    {columns.map(c => <TableHeaderCell label={c.label} flex={c.flex} property={c.property} key={`thead-${c.property}`} selected={selectedColumn === c.property} sortDirection={sortDirection}  setSelectedColumn={setSelectedColumn} setSortDirection={setSortDirection}/>)}
  </View>;
}

function TableHeaderCell({ label, flex, property, selected, sortDirection, setSelectedColumn, setSortDirection }: { label: string, flex: number, property: string, selected: boolean, sortDirection: SortDirection, setSelectedColumn: (column: string) => void, setSortDirection: (direction: SortDirection) => void }) {
  const handlePress = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    selected
      ? sortDirection === "asc"
        ? setSortDirection("desc")
        : setSortDirection("asc")
      : setSelectedColumn(property)
  }

  const sortIcon = selected
    ? sortDirection === "asc"
      ? <AntDesign name="caretup" size={16} />
      : <AntDesign name="caretdown" size={16} />
    : null

  return <TouchableOpacity style={[styles.thead, { flex }, styles.row]} onPress={handlePress}>
    <Text style={styles.theadLabel}>{label}{" "}</Text>
    { sortIcon }
  </TouchableOpacity>;
}

function TableRow({ workOrder, columns }: { workOrder: WorkOrder, columns: Column[] }) {
  const navigation = useNavigation();

  return (
    <TouchableOpacity onPress={() => navigation.navigate('Show', { workOrderID: workOrder.id})}>

    <View style={styles.trow}>
      {columns.map(c => <TableDataCell workOrder={workOrder} column={c} key={`table-row-${c.accessor}`} />)}
    </View>
    </TouchableOpacity>
  );
}

function TableDataCell({ workOrder, column }: { workOrder: WorkOrder, column: Column }) {
  let content;
  if (typeof column.accessor === "function") {
    content = column.accessor(workOrder);
  } else {
    content = (<Text style={styles.cellText}>{workOrder[column.accessor]}</Text>);
  }
  return (
    <View style={[styles.cell, { flex: column.flex }]}>
      {content}
    </View>

  );
}

export default WorkOrderListScreen;

import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import UsersListScreen from "../screens/UsersListScreen";
import ShowUserScreen from "../screens/ShowUserScreen";

export type UserStackParamList = {
  List: undefined,
  Show: { userID: string }
}
const Stack = createStackNavigator<UserStackParamList>();

export default function UserTab() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="List"
        component={UsersListScreen}
        options={{
          title: "Users",
        }}
      />
      <Stack.Screen name="Show" component={ShowUserScreen} options ={{ title: "Show User"}} />
    </Stack.Navigator>
  );
}
import React from "react";
import { ScrollView, Text, View } from "react-native";
import { Formik } from "formik";
import { Button } from "react-native-elements";
import { useNavigation } from "@react-navigation/native";
import { WorkOrderFieldValues, WorkOrderFormValues } from "../../types/WorkOrder";
import styles from "../../styles/Form";
import JobToggle from "./JobToggle";
import NewJobFields from "./NewJobFields";
import SelectJobField from "./SelectJobField";
import { formSubmit, WorkOrderFormSchema } from "../../services/WorkOrderFormService";
import DetailFields from "./DetailFields";
import LineItemsInput from "../LineItemsInput/LineItemsInput";
import { UserContext } from "../../providers/UserProvider";
import { assertIsProfile } from "../../services/ProfileService";


function WorkOrderForm({ workOrder }: { workOrder: WorkOrderFieldValues }) {
  const navigation = useNavigation();

  const initValues: WorkOrderFormValues = {
    job: {
      new: !workOrder.id,
      identifier: "",
      name: "",
      clientID: "",
      id: workOrder?.job?.id,
    },
    workOrder,
  };

  const { profile } = React.useContext(UserContext);
  assertIsProfile(profile);

  return (
    <ScrollView>
      <View style={styles.container}>
        <Formik
          initialValues={initValues}
          onSubmit={(res) => formSubmit(res, profile).then(() => navigation.navigate("List"))}
          validationSchema={WorkOrderFormSchema}
        >
          {({ values, handleSubmit }) => (
            <View style={styles.formContainer}>
              {
                values.workOrder.id ? (
                  <>
                    <View style={styles.row}>
                      <View style={styles.column}>
                        <Text style={styles.title}>
                          Edit #{values.workOrder.identifier} - {values.workOrder.name}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.row}>
                      <View style={styles.column}>
                        <Text style={{ fontSize: 20 }}>For {values.workOrder.clientName}</Text>
                      </View>
                    </View>
                  </>
                ) : (
                  <>
                    <JobToggle />
                    {values.job.new ? <NewJobFields /> : <SelectJobField />}
                  </>
                )
              }
              <View style={styles.row}>
                <Text style={styles.title}>Details</Text>
              </View>
              <DetailFields />
              <View style={styles.row}>
                <Text style={styles.title}>Items</Text>
              </View>
              <View style={styles.row}>
                <View style={styles.column}>
                  <LineItemsInput />
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.column}>
                  <Button
                    type="solid"
                    title="Submit"
                    onPress={handleSubmit}
                    raised
                  />
                </View>
              </View>
            </View>
          )
          }
        </Formik>
      </View>
    </ScrollView>

  );
}


export default WorkOrderForm;
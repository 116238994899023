import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { useNavigation } from "@react-navigation/native";

import styles, { statusStyle } from "../styles/Card";
import WorkOrder from "../types/WorkOrder";

export default function WorkOrderCard({ document }: { document: WorkOrder }) {

  const navigation = useNavigation();
  // console.log("render wo card: ", document);
  const { name, identifier, status, assigneeName, clientName, startAt } = document;
  return (
    <TouchableOpacity
      onPress={() =>
        navigation.navigate("Show", { workOrderID: document.id })
      }
    >
      <View style={styles.card}>
        <View style={styles.row}>
          <Text style={styles.identifier}>#{identifier}</Text>
          <Text style={styles.cardTitle}> - {name}</Text>
        </View>
        <View style={styles.row}>
          <Text style={statusStyle(status)}>{status} </Text>
          <Text style={styles.body}>
            by {assigneeName} for {clientName}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.body}>
            {startAt.toLocaleString([], {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}


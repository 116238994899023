import moment        from "moment";
import { nanoid }    from "nanoid/async/index.native";
import { fuego, getDocument } from "@nandorojo/swr-firestore";
import { firestore } from "../config/fb";
import Yup           from "../config/Yup";
import WorkOrder, { Item, ItemGroup, WorkOrderFieldValues, WorkOrderFormValues } from "../types/WorkOrder";
import Profile from "../types/Profile";
import Job from "../types/Job";
import Client from "../types/Client";

export const defaultFormState = {
  job: {
    new: true,
    identifier: "",
    name: "",
    clientID: "",
    selectedJob: "",
  },
  startAt: null,
  assigneeID: "",
  location: "",
  contact: "",
  narrative: "",
  notes: "",
  itemGroups: [
    {
      id: "40ai128Gk",
      name: "",
      items: [
        {
          id: "12389asdk",
          identifier: "",
          name: "",
          expected: 0,
          actual: 0,
          unit: "",
        },
      ],
    },
  ],
  
};

// @ts-ignore
export async function createItemGroup({ arrayHelpers }) {
  const [groupId, itemId] = await Promise.all([nanoid(), nanoid()]);
  
  const newGroup = {
    id: groupId,
    name: "",
    items: [
      {
        id: itemId,
        name: "",
        identifier: "",
        expected: "",
        actual: 0,
        unit: "",
      },
    ],
  };
  
  arrayHelpers.push(newGroup);
}

// @ts-ignore
export async function createItem({ arrayHelpers }) {
  const id = await nanoid();
  
  const item = {
    id,
    identifier: "",
    name: "",
    expected: "",
    actual: 0,
    unit: "",
  };
  
  arrayHelpers.push(item);
  
}


export function handleItemPaste({ value, index, arrayHelpers }) {
  const items = value.split(/\r\n|\r|\n/g).map(line => line.split("\t"));
  // arrayHelpers.remove(index)
  items.forEach((item: string[]) => {
    nanoid().then((id: string) => arrayHelpers.push({
      id,
      identifier: item[0] || "",
      name: item[1] || "",
      expected: parseFloat(item[2]) || 0,
      actual: 0,
      unit: item[3] || "",
    }));
  });
  arrayHelpers.remove(index);
}

function sanitizeItem(item: Item) {
  return ({
    ...item,
    expected: parseFloat(String(item.expected)),
    actual: parseFloat(String(item.actual)),
  })
}

function sanitizeItemGroup(itemGroup: ItemGroup) {
  const items = itemGroup.items.map(sanitizeItem)
  return ({
    ...itemGroup,
    items,
  })
}

 function sanitizeWorkOrderFormValues(values: WorkOrderFieldValues): WorkOrder {
  const itemGroups = values.itemGroups.map(sanitizeItemGroup)

  return({
    ...values,
    itemGroups,
  })
}





export async function formSubmit(values: WorkOrderFormValues, profile: Profile) {
  let jobRef; let clientRef;
  let workOrderRef;



  console.log('formSubmit: ', values)

  if(values.job.new && !values.workOrder.id) {
    clientRef = profile.company.collection('clients').doc(values.job.clientID);
    jobRef = profile.company.collection('jobs').doc()
    await jobRef.set({
      identifier: values.job.identifier,
      name: values.job.name,
      client: clientRef,
      clientID: clientRef.id,
      workOrderCount: 0,
    })
  } else {
    console.log('this is an edit or using existing job. for job: ', values.job.id)
    jobRef = profile.company.collection('jobs').doc(values.job.id)
  }

  if(values.workOrder.id) {
    console.log('this is an edit. for work order: ', values.workOrder.id)
    workOrderRef = profile.company.collection('work_orders').doc(values.workOrder.id)
  } else {
    workOrderRef = profile.company.collection('work_orders').doc()
  }

  const job = await getDocument<Job>(jobRef.path)
  console.log('fetched job: ', job)
  if(!clientRef) clientRef = job.client
  console.log('clientref should be set: ', clientRef.id)
  const client = await getDocument<Client>(`${profile.company.path}/clients/${clientRef.id}`)
  console.log('fetched client: ', client)
  const assignee = await getDocument<Profile>(`/profiles/${values.workOrder.assigneeID}`)
  console.log('fetched assignee: ', assignee)

  // delete snapshot fields which can come from edit.
  // @ts-ignore
  delete values.workOrder.__snapshot
  delete values.workOrder.exists
  delete values.workOrder.id
  delete values.workOrder.hasPendingWrites

  const settingValues = {
    ...sanitizeWorkOrderFormValues(values.workOrder),
    client: clientRef,
    clientName: client.name,
    clientID: client.id,
    job: jobRef,
    jobID: job.id,
    assigneeName: assignee.name,
    assignee: fuego.db.doc(`/profiles/${values.workOrder.assigneeID}`),
    name: job.name,
    archived: false,
    // identifier: `${job.identifier}-${String(job.workOrderCount + 1).padStart(2, "0")}`,
  }

  console.log('setting work order: ', settingValues)



  return workOrderRef.set(settingValues)

}

// export const defaultFormState = {
//   job: {
//     new: true,
//     identifier: "20-3030",
//     name: "Demo Job",
//     clientID: "ZgsvET0MYQPOF44iCkVW",
//     selectedJob: "",
//   },
//   startAt: moment().format("MM/DD/YYYY h:mm a"),
//   assigneeID: "ZkzlnE1y12b6RxdZmA3yZ0sGzcP2",
//   location: "1600 Pennsylvania Avenue NW, Washington, DC 20500",
//   contact: "sholt@example.com (321)332-2601",
//   narrative:
//     "Dis nisl cursus ornare nunc penatibus dictum hendrerit taciti suscipit, torquent integer et sociis lobortis inceptos felis. Sit ligula tellus arcu fames lectus ornare mauris a pharetra porttitor orci dapibus, risus aliquam quisque vulputate nulla luctus euismod montes nam ridiculus facilisis. Tempor nisl orci taciti sociis mus erat nascetur condimentum tempus magna accumsan, nisi pretium lacinia non suscipit porta sem cum facilisi risus volutpat, torquent nec neque vehicula in potenti lorem dignissim cras nunc.",
//   notes:
//     "Pulvinar neque metus mi dictumst lectus sodales pretium vitae, lacus egestas nam tincidunt mattis diam sagittis arcu, imperdiet facilisi duis integer pellentesque ornare venenatis. Lacus etiam fames lectus hendrerit diam eleifend conubia et arcu, condimentum dapibus venenatis pharetra ridiculus purus ornare magnis elit malesuada, cubilia sit gravida class bibendum molestie ligula facilisis. Posuere platea morbi ad at duis parturient lacinia senectus diam feugiat mus, nulla vivamus natoque congue lobortis sollicitudin a fames gravida bibendum pellentesque, non luctus justo semper habitasse vitae rutrum scelerisque pharetra tellus.",
//   quantities: [
//     {
//       identifier: "711-11-121",
//       name: '6" Thermoplastic, White',
//       expected: 1800,
//       actual: 0,
//       unit: "LF",
//     },
//     {
//       identifier: "711-11-221",
//       name: '6" Thermoplastic, Yellow',
//       expected: 3600,
//       actual: 0,
//       unit: "LF",
//     },
//   ],
// };

export const WorkOrderFormSchema = Yup.object().shape({
  job: Yup.object().shape({
    new: Yup.boolean(),
    identifier: Yup.string().when("new", {
      is: true,
      then: Yup.string().required("Required."),
      otherwise: Yup.string().nullable(),
    }),
    clientID: Yup.string().when("new", {
      is: true,
      then: Yup.string().required("Required."),
      otherwise: Yup.string().nullable(),
    }),
    name: Yup.string().when("new", {
      is: true,
      then: Yup.string().required("Required."),
      otherwise: Yup.string().nullable(),
    }),
    id: Yup.string().when("new", {
      is: false,
      then: Yup.string().required("Required."),
      otherwise: Yup.string().nullable(),
    }),
  }),
  assigneeID: Yup.string().when("assignee", {
    is: null,
    then: Yup.string().required("Required"),
    otherwise: Yup.string().nullable(),
  }),
  startAt: Yup.date(),
  location: Yup.string(),
  contact: Yup.string(),
  narrative: Yup.string(),
  notes: Yup.string(),
  itemGroups: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      name: Yup.string(),
      items: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required("Item missing internal ID"),
          name: Yup.string().default(""),
          expected: Yup.number().default(0),
          actual: Yup.number().default(0),
        }).uniqueProperty("id", "An internal item ID was duplicated; this shouldn't ever happen"),
      ),
    }).uniqueProperty("id", "An item id was duplicated; this should never happen"),
  ),
  quantities: Yup.array().of(
    Yup.object()
    .shape({
      identifier: Yup.string(),
      name: Yup.string(),
      expected: Yup.number(),
      unit: Yup.string(),
    })
    .uniqueProperty("name", "Names must be unique."),
  ),
});

export async function createWorkOrder({ profile, values }) {
  let jobRef;
  
  if (values.job.new) {
    // console.log("new job");
    const clientRef = profile.company
    .collection("clients")
    .doc(values.job.clientID);
    jobRef = profile.company.collection("jobs").doc();
    jobRef.set({
      identifier: values.job.identifier,
      name: values.job.name,
      client: clientRef,
      workOrderCount: 1,
    });
  } else {
    jobRef = profile.company.collection("jobs").doc(values.job.selectedJob);
  }
  
  const assigneeRef = firestore.collection("profiles").doc(values.assigneeID);
  
  const ref = profile.company.collection("work_orders").doc();
  try {
    const [job, assignee] = await Promise.all([jobRef.get(), assigneeRef.get()]);
    return await ref.set({
      name: job.data().name,
      identifier: `${job.data().identifier}-01`,
      assigneeName: assignee.data().name,
      clientName: "",
      job: jobRef,
      assignee: assigneeRef,
      startAt: moment(values.startAt, "MM/DD/YYYY h:mm a", false).toDate(),
      location: values.location,
      contact: values.contact,
      narrative: values.narrative,
      notes: values.notes,
      status: "upcoming",
      itemGroups: values.itemGroups,
    });
  } catch (error) {
    return null;
  }
}

export async function updateWorkOrder({ profile, workOrderID, values }) {
  console.log("update work order.", workOrderID);
  const assignee = firestore.collection("profiles").doc(values.assigneeID);
  return profile
  .company
  .collection("work_orders")
  .doc(workOrderID)
  .set({ ...values, assignee });
}
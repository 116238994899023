import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import {
  Platform,
  Alert,
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Button,
}                                 from "react-native";
import {
  ExpandableCalendar,
  AgendaList,
  CalendarProvider,
  WeekCalendar,
}                                 from "react-native-calendars";
import { AntDesign }              from "@expo/vector-icons";
import { streamActiveWorkOrders } from "../services/WorkOrderService";
import { UserContext }            from "../providers/UserProvider";
import WorkOrderAgendaItem        from "../components/WorkOrderAgendaItem";

// // const testIDs = require("../testIDs");
const themeColor = "indigo";
const lightThemeColor = "indigo";


export default function WorkOrderCalendarScreen() {
  return (
    <UserContext.Consumer>
      {({ profile }) => <WorkOrderCalendar profile={profile} />}
    </UserContext.Consumer>
  );
}

export class WorkOrderCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workOrders: [],
      unsubscribe: null,
      markedDates: {},
    };
  }

  

  componentDidMount() {
    this.initWorkOrderStream(this.props.profile);
  }

  componentWillUnmount() {
    // console.log("shutting down calendar subscriptions");
    this.state.unsubscribe();
  }
  
  setWorkOrders = (workOrders) => {
    // console.log("setWorkOrders");
    
    const byDate = {};
    
    workOrders.forEach((workOrder) => {
      // console.log("iterating through work order: ", workOrder.data());
      const dateString = moment(workOrder.data().startAt.toDate()).format(
        "YYYY-MM-DD"
      );
      
      byDate[dateString] = byDate[dateString] || [];
      
      byDate[dateString].push(workOrder);
    });
    
    // console.log("bydate: ", byDate);
    
    const agenda = Object.keys(byDate).map((dateString) => ({
      title: dateString,
      data: byDate[dateString],
    }));
    
    const markedDates = {};
    Object.keys(byDate).forEach(
      (dateString) => (markedDates[dateString] = { marked: true })
    );
    
    // console.log("marked dates: ", markedDates);
    
    this.setState({ workOrders: agenda, markedDates });
  };
  
  initWorkOrderStream = (profile) => {
    // console.log("\n===\nstream workorders for calendar\n===\n", profile);
    const unsubscribe = streamActiveWorkOrders(profile, this.setWorkOrders);
    this.setState({ unsubscribe });
  };
  
  onDateChanged = (date, updateSource) => {
    // console.log("ExpandableCalendarScreen onDateChanged: ", date, updateSource);
    // fetch and set data for date + week ahead
  };
  
  onMonthChange = (month, updateSource) => {
    // console.log(
    //   "ExpandableCalendarScreen onMonthChange: ",
    //   month,
    //   updateSource
    // );
  };
  
  buttonPressed() {
    Alert.alert("show more");
  }
  
  itemPressed(id) {
    Alert.alert(id);
  }
  
  renderEmptyItem() {
    return (
      <View style={styles.emptyItem}>
        <Text style={styles.emptyItemText}>No Work Orders Scheduled</Text>
      </View>
    );
  }
  
  renderItem = ({ item }) => {
    if (_.isEmpty(item)) {
      return this.renderEmptyItem();
    }
    
    return (
      <WorkOrderAgendaItem item={item} />
    )
  };
  
  getMarkedDates = () => {
    const marked = {};
    this.state.workOrders.forEach((item) => {
      // NOTE: only mark dates with data
      if (item.data && item.data.length > 0 && !_.isEmpty(item.data[0])) {
        marked[item.title] = { marked: true };
      } else {
        marked[item.title] = { disabled: true };
      }
    });
    return marked;
  };
  
  getTheme = () => {
    const disabledColor = "grey";
    
    return {
      // arrows
      arrowColor: "black",
      arrowStyle: { padding: 0 },
      // month
      monthTextColor: "black",
      textMonthFontSize: 16,
      textMonthFontFamily: "HelveticaNeue",
      textMonthFontWeight: "bold",
      // day names
      textSectionTitleColor: "black",
      textDayHeaderFontSize: 800,
      textDayHeaderFontFamily: "HelveticaNeue",
      textDayHeaderFontWeight: "normal",
      // dates
      dayTextColor: themeColor,
      textDayFontSize: 800,
      textDayFontFamily: "HelveticaNeue",
      textDayFontWeight: "500",
      textDayStyle: { marginTop: Platform.OS === "android" ? 2 : 4 },
      // selected date
      selectedDayBackgroundColor: themeColor,
      selectedDayTextColor: "white",
      // disabled date
      textDisabledColor: disabledColor,
      // dot (marked date)
      dotColor: themeColor,
      selectedDotColor: "white",
      disabledDotColor: disabledColor,
      dotStyle: { marginTop: -2 },
    };
  };

  render() {
    return (
      <>
        <CalendarProvider
          // date={this.state.activeMonth.toDate}
          date={new Date()}
          onDateChanged={this.onDateChanged}
          onMonthChange={this.onMonthChange}
          showTodayButton
          disabledOpacity={0.6}
          theme={this.getTheme()}
          // theme={{
          //   todayButtonTextColor: themeColor
          // }}
          // todayBottomMargin={16}
        >
          {this.props.weekView ? (
            <WeekCalendar
              // testID={testIDs.weekCalendar.CONTAINER}
              firstDay={0}
              markedDates={this.state.markedDates}
              onDateChanged={this.onDateChanged}
              renderArrow={(dir) => <AntDesign size={18} name={dir} />}
            />
          ) : (
            <ExpandableCalendar
              // testID={testIDs.expandableCalendar.CONTAINER}
              // horizontal={false}
              // hideArrows
              // disablePan
              // hideKnob
              // initialPosition={ExpandableCalendar.positions.OPEN}
              // calendarStyle={styles.calendar}
              // headerStyle={styles.calendar} // for horizontal only
              // disableWeekScroll
              // theme={this.getTheme()}
              disableAllTouchEventsForDisabledDays
              firstDay={0}
              markedDates={this.state.markedDates} // {'2019-06-01': {marked: true}, '2019-06-02': {marked: true}, '2019-06-03': {marked: true}};
              renderArrow={(dir) => <AntDesign size={24} name={dir} />}

              // leftArrowImageSource={require("../assets/favicon.png")}
              // rightArrowImageSource={require("../assets/favicon.png")}
            />
          )}
          {/* <View><Text>Agenda goes here</Text></View> */}
           <AgendaList
            sections={this.state.workOrders}
            extraData={this.state}
            renderItem={this.renderItem}
            // sectionStyle={styles.section}
           /> 
        </CalendarProvider>
      </>
    );
  }
}

const styles = StyleSheet.create({
  calendar: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  section: {
    backgroundColor: lightThemeColor,
    color: "#444",
    textTransform: "capitalize",
  },
  item: {
    padding: 20,
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderBottomColor: "lightgrey",
    flexDirection: "row",
  },
  itemHourText: {
    color: "#333",
    fontWeight: "500",
  },
  itemDurationText: {
    color: "grey",
    fontSize: 12,
    marginTop: 4,
    marginLeft: 4,
  },
  itemTitleText: {
    color: "black",
    marginLeft: 16,
    fontWeight: "bold",
    fontSize: 16,
  },
  itemButtonContainer: {
    flex: 1,
    alignItems: "flex-end",
  },
  emptyItem: {
    paddingLeft: 20,
    height: 52,
    justifyContent: "center",
    borderBottomWidth: 1,
    borderBottomColor: "lightgrey",
  },
  emptyItemText: {
    color: "lightgrey",
    fontSize: 14,
  },
  workOrderIdentifier: {
    fontWeight: "600",
  },
});

import React                      from "react";
import PropTypes                  from "prop-types";
import { StyleSheet, Text, View } from "react-native";

const Item = ({ item }) => {
  const { identifier, name, expected, actual, unit } = item;
  return (
    <View style={styles.row}>
      <Text style={[styles.cell, styles.identifier]}>{identifier}</Text>
      <Text style={[styles.cell, styles.name]}>{name}</Text>
      <Text style={[styles.cell, styles.expected]}>{expected} {unit}</Text>
      <Text style={[styles.cell, styles.actual]}>{actual} {unit}</Text>
      {/* <Text>{JSON.stringify(item)}</Text> */}
    </View>
  );
};

ItemGroupTable.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
    )
  }).isRequired
};


function ItemGroupTable({ group }) {
  const { name, items } = group;
  
  return (
    <View style={styles.table}>
      <Text style={styles.title}>
        {name}
      </Text>
      <View style={styles.headers}>
        <Text style={[styles.header, styles.identifier]}>
          Identifier
        </Text>
        <Text style={[styles.header, styles.name]}>
          Name
        </Text>
        <Text style={[styles.header, styles.expected]}>
          Expected
        </Text>
        <Text style={[styles.header, styles.actual]}>
          Actual
        </Text>
      
      </View>
      {items.map(item => (
        <Item key={item.id} item={item} />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  table: {
    flexDirection: "column",
    marginBottom: 20,
    // maxWidth: 1200
  },
  row: {
    flexDirection: "row"
  },
  headers: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#ccc"
  },
  title: {
    fontWeight: "400",
    fontSize: 20,
    marginBottom: 5
  },
  header: {
    fontSize: 18,
    fontWeight: "600"
  },
  cell: {
    fontSize: 17
  },
  identifier: {
    flex: 1
  },
  name: {
    flex: 2
  },
  expected: {
    flex: 1
  },
  actual: {
    flex: 1
  }
  
});

export default ItemGroupTable;
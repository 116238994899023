import React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { RouteProp } from "@react-navigation/native";
import { UserStackParamList } from "../navigation/UserTab";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
})


type Props = {
  route: RouteProp<UserStackParamList, "Show">
}

export default function ShowUserScreen({ route }: Props) {
  const { userID } = route.params;

  return (
    <View style={styles.container}>
      <Text>Show User {JSON.stringify(userID)}</Text>
    </View>
  )
}
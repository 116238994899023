import React, { useState, useEffect }               from "react";
import PropTypes                                                from "prop-types";
import { View, Text, ActivityIndicator, TextInput, ScrollView } from "react-native";
import { Formik, FieldArray }                                   from "formik";
import { Button }      from "react-native-elements";
import { UserContext } from "../providers/UserProvider";
import {
  streamWorkOrder,
  createSubmission,
  SubmissionSchema,
}                      from "../services/WorkOrderService";
import styles          from "../styles/Form";
import OutlineButton   from "../components/OutlineButton";
import RaisedButton    from "../components/RaisedButton";
import StatusSelector  from "../components/StatusSelector";
import LineItemsInput  from "../components/LineItemsInput/LineItemsInput";

// eslint-disable-next-line react/prop-types
export default function NewSubmissionScreen({ navigation, route }) {
  const { workOrderID } = route.params;
  
  return (
    <UserContext.Consumer>
      {({ profile }) => (
        <ScrollView>
          <NewSubmission
            profile={profile}
            workOrderID={workOrderID}
            navigation={navigation}
          />
        </ScrollView>
        
      )}
    </UserContext.Consumer>
  );
}

NewSubmissionScreen.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      workOrderID: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

function NewSubmission({ profile, workOrderID, navigation }) {
  const [workOrder, setWorkOrder] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(0);
  
  const statusLabels = ["Upcoming", "In Progress", "Delayed", "Complete"];
  const statuses = ["upcoming", "in_progress", "delayed", "complete"];
  
  // console.log('===\nNEW SUBMISSION COMPONENT\n===')
  useEffect(() => {
    // console.log('STREAM WORK ORDER FOR NEW SUBMISSION')
    return streamWorkOrder({ profile, workOrderID, setWorkOrder });
  }, [profile, setWorkOrder]);
  
  useEffect(() => {
    // console.log('work order status effect')
    if (workOrder) {
      const index = statuses.indexOf(workOrder.status);
      if (index !== -1) {
        setSelectedStatus(index);
      }
    }
  }, [workOrder]);
  
  return (
    <View style={styles.container}>
      {workOrder ? (
        <Formik
          initialValues={{
            workOrderID,
            itemGroups:
              workOrder.itemGroups &&
              workOrder.itemGroups.map(({ id, name, items }) => ({
                id,
                name,
                items: items.map(({ id, identifier, name, expected, unit}) => ({ id, identifier, name, expected, unit, actual: 0 })),
              })),
            status: workOrder.status,
          }}
          validationSchema={SubmissionSchema}
          onSubmit={(values) => {
            console.log('going to submit submission: ', values)
            createSubmission({ profile, data: values });
            navigation.navigate("Show", { workOrderID });
          }}
        >
          {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
            <>
              <View style={[styles.row, { justifyContent: "space-around" }]}>
                <FieldArray
                  name="itemGroups"
                  render={(arrayHelpers) => (
                    <View>
                      <LineItemsInput isSubmission itemGroups={values.itemGroups}/>
                      
                      
                      <StatusSelector statusLabels={statusLabels}
                                      selectedStatus={selectedStatus}
                                      setSelectedStatus={setSelectedStatus}
                                      setFieldValue={setFieldValue}
                                      statuses={statuses}
                      />
                      <View
                        style={[
                          styles.row,
                          { justifyContent: "space-between" },
                        ]}
                      >
                        <Button title="Submit" onPress={handleSubmit} />
                      </View>
                    </View>
                  )}
                />
              </View>
            </>
          )}
        </Formik>
      ) : (
         <View>
           <ActivityIndicator size="large" color="indigo" />
           <Text>Fetching work order...</Text>
         </View>
       )}
    </View>
  );
}

import React from "react";
import {
  Text,
  View,
  StyleSheet,
  TextInput,
  Button,
  KeyboardAvoidingView,
} from "react-native";
import { Formik } from "formik";

import { fuego } from "@nandorojo/swr-firestore";
import { auth }  from "../config/fb";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 15,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 15,
  },
  subhead: {
    fontSize: 20,
    color: "#333",
    marginBottom: 15,
  },
  error: {
    marginBottom: 20,
    height: 17.5,
  },
  formContainer: {
    // width: 400
  },
  input: {
    height: 40,
    width: 300,
    paddingHorizontal: 5,
    backgroundColor: "white",
    marginBottom: 0,
    paddingVertical: 5,
    // borderBottomWidth: 2,
    // borderColor: "#888",
    // borderWidth: 2,
    // borderRadius: 4,
  },
  label: {
    fontWeight: "600",
    fontSize: 17,
    textAlign: "left",
    color: "#555",
  },
  inputContainer: {
    marginBottom: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 2,
      height: 4,
    },
    shadowOpacity: 0.4,
    shadowRadius: 2.62,
    elevation: 4,
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: "#888",
    width: 400,
    marginVertical: 10,
  },
});

function SignInScreen() {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Welcome</Text>
      <Text style={styles.subhead}>
        Enter your login information to get started.
      </Text>
      <View style={styles.divider} />
      <Formik
        initialValues={{ email: "", password: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required.";
          }
          if (!values.password) {
            errors.password = "Required.";
          }
        }}
        onSubmit={(values) =>
          fuego.auth().signInWithEmailAndPassword(values.email, values.password)
        }
      >
        {({ handleChange, handleBlur, handleSubmit, values }) => (
          <KeyboardAvoidingView behavior="padding" style={styles.formContainer}>
            <Text style={styles.label}>Email</Text>

            <View style={styles.inputContainer}>
              <TextInput
                keyboardType="email-address"
                style={styles.input}
                onChangeText={handleChange("email")}
                onBlur={handleBlur("email")}
                value={values.email}
              />
            </View>
            <Text style={styles.label}>Password</Text>

            <View style={styles.inputContainer}>
              <TextInput
                style={styles.input}
                onChangeText={handleChange("password")}
                onBlur={handleBlur("password")}
                value={values.password}
                secureTextEntry
              />
            </View>

            <Button onPress={handleSubmit} title="Sign In" />
          </KeyboardAvoidingView>
        )}
      </Formik>
    </View>
  );
}

export default SignInScreen;

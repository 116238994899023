import { fuego } from "@nandorojo/swr-firestore";
import Profile from "../types/Profile";

// eslint-disable-next-line import/prefer-default-export
// @ts-ignore
export function streamCompanyProfiles({ profile, setProfiles }) {
  // @ts-ignore
  // eslint-disable-next-line no-console
  console.error("=====STREAM COMPANY PROFILES CALLED=====\nthis is to be deprecated")
  return fuego.db
  .collection("profiles")
  .where("company", "==", profile.company)
  .onSnapshot({
    next: (querySnapshot) => {
      const updatedProfiles = querySnapshot.docs.map((doc) => ({
        documentID: doc.id,
        data: doc.data(),
      }));
      // console.log("fetched profiles", updatedProfiles);
      setProfiles(updatedProfiles);
    },
  });
}

export function assertIsProfile(val: any): asserts val is Profile {
  if(!(val as Profile).role) {
    throw new Error("AssertionError: Invalid Profile Supplied.")
  }
}

export function canDeleteDocument(profile: Profile): boolean {
  return profile.role !== 'foreman'
}

export function canDeletePhoto(profile: Profile): boolean {
  switch (profile.role) {
    default:
      return true;
  }
}
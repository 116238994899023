import React                            from "react";
import PropTypes                        from "prop-types";
import { View, Text, StyleSheet }       from "react-native";
import { FieldArray, useFormikContext } from "formik";
import { Button }                       from "react-native-elements";
import { Feather }                      from "@expo/vector-icons";
import ItemGroup                        from "./ItemGroup";
import RaisedButton        from "../RaisedButton";
import { createItemGroup } from "../../services/WorkOrderFormService";


LineItemsInput.propTypes = {
  isSubmission: PropTypes.bool,
};

LineItemsInput.defaultProps = {
  isSubmission: false,
};


function LineItemsInput({ isSubmission }) {
  console.log('=========\nisSubmission: ', isSubmission, "\n=========")
  const { values } = useFormikContext();
  console.log('LII values:', values)
  const itemGroupValues = isSubmission ? values.itemGroups : values.workOrder.itemGroups
  
  const add = <Feather name="plus" size={24} color="indigo" />;
  
  const fieldName = isSubmission ? `itemGroups` : `workOrder.itemGroups`
  
  return (
    <FieldArray name={fieldName}>
      {(arrayHelpers) => (
        <View style={styles.column}>
            
            {
              itemGroupValues.length > 0 &&
              itemGroupValues.map((group, index) => (
                <ItemGroup
                  key={group.id}
                  group={group}
                  index={index}
                  style={styles.itemGroup}
                  isSubmission={isSubmission}
                />
              
              ))
              
            }
          
          
          {/* <View> */}
          {/*  <Text> */}
          {/*    {JSON.stringify(values.itemGroups)} */}
          {/*  </Text> */}
          {/* </View> */}
          <Button
            type="outline"
            containerStyle={{ padding: 5 }}
            buttonStyle={{ borderColor: "indigo" }}
            color="indigo"
            icon={add}
            title="Add Group"
            titleStyle={{ color: "indigo " }}
            onPress={() => createItemGroup({ arrayHelpers })}
          />
          <View style={styles.row}>
            <Feather name="info" color="#555" size={24}/>
            <Text style={styles.tip}> You can paste items from Excel into this form.</Text>
            
          </View>
      </View>
      )}
    
    </FieldArray>
  );
}

const styles = StyleSheet.create({
  itemGroup: {
    flex: 1,
    borderColor: "red",
  },
  groupList: {
    flexDirection: "column",
    alignItems: "flex-start",
    // borderColor: "red",
    // borderWidth: 5,
    flex: 1,
  },
  container: {
    flex: 1,
    width: "100%",
  },
  tip: {
    fontSize: 18,
    color: '#555',
  },
  row: {
    flexDirection: 'row',
  },
});

export default LineItemsInput;
import { StyleProp, StyleSheet, TextStyle } from "react-native";
import { Status } from "../types/WorkOrder";


const stdStatusStyle: StyleProp<TextStyle> = {
  fontWeight: "bold",
  fontSize: 17,
};

const statusStyles = StyleSheet.create({
  "in_progress": {
    ...stdStatusStyle,
    color: "#f2c443",
  },
  "delayed": {
    ...stdStatusStyle,
    color: "#e5341d",
  },
  "complete": {
    ...stdStatusStyle,
    color: "#32721d",
  },
  "default": {
    ...stdStatusStyle,
    color: "#222",
  },
});

export function statusStyle(status: Status): StyleProp<TextStyle> {

  switch (status) {
    case "in_progress":
      return statusStyles.in_progress
    case "delayed":
      return statusStyles.delayed
    case "complete":
      return statusStyles.complete
    default:
      return statusStyles.default
  }
}

export default StyleSheet.create({
  card: {
    // flexBasis: "34%",
    width: 600,
    // width: "48%",
    padding: 10,
    borderWidth: 1,
    borderColor: "#eee",
    marginRight: 20,
    marginBottom: 20,
    borderRadius: 8,
    elevation: 10,
    shadowColor: "black",
    shadowOffset: {
      width: 2,
      height: 4,
    },
    shadowRadius: 2.62,
    shadowOpacity: 0.4,
    backgroundColor: "white",
  },
  cardText: {
    fontSize: 17,
  },
  identifier: {
    fontWeight: "600",
    fontSize: 17,
  },
  row: {
    flexDirection: "row",
    marginTop: 3,
  },
  cardTitle: {
    fontSize: 20,
  },
  body: {
    fontSize: 17,
  },
});

import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import * as Linking from "expo-linking";
import React from "react";
import { Feather } from "@expo/vector-icons";
import { Button } from "react-native-elements";
import styles from "../../styles/WorkOrder";
import { UserContext } from "../../providers/UserProvider";
import { assertIsProfile, canDeleteDocument } from "../../services/ProfileService";
import { StorageFile } from "../../types/StorageFile";
import { deleteFileAtPath } from "../../services/StorageService";

const deleteStyle = StyleSheet.create({
  button: {
    marginRight: 10,
  },
  buttonContainer: {
    marginRight: 20,
  },
  deleteContainer: {
    borderWidth: 1,
    borderColor: '#999',
  },
});

export default function DocumentListItem({ document }: { document: StorageFile }) {
  const { name, downloadURL, path } = document;

  const { profile } = React.useContext(UserContext);
  assertIsProfile(profile);

  const [showDelete, setShowDelete] = React.useState(false)

  const prefix = canDeleteDocument(profile) ? (
    <TouchableOpacity
      style={deleteStyle.button}
      onPress={() => setShowDelete(!showDelete)}>
      <Feather name="trash-2" size={24} color="#aaa" />
    </TouchableOpacity>
  ) : null;


  return (
    <>
    <View style={styles.row} key={downloadURL}>
      {prefix}
      <TouchableOpacity onPress={() => Linking.openURL(downloadURL)}>
        <Text style={[styles.body, { color: "indigo", height: 24 }]}>{name}</Text>
      </TouchableOpacity>
    </View>
      { showDelete ? (
      <View style={deleteStyle.deleteContainer}>
        <View style={styles.row}>
          <Text style={styles.body}>
            Really delete {name}? This cannot be undone.
          </Text>
        </View>
        <View style={styles.row}>
          <View style={deleteStyle.buttonContainer}>
            <Button
              type="outline"
              title="Cancel"
              onPress={() => setShowDelete(false)}
            />
          </View>
          <View style={deleteStyle.buttonContainer}>
            <Button
              type="solid"
              title="Permanently Delete"
              onPress={() => deleteFileAtPath(path)}
            />
          </View>
        </View>
      </View>

      ) : null}
    </>

  );
}
import * as Yup from 'yup';

// eslint-disable-next-line func-names
Yup.addMethod(Yup.object, "uniqueProperty", function (propertyName, message) {
  // eslint-disable-next-line func-names
  return this.test("unique", message, function (value) {
    if (!value || !value[propertyName]) {
      return true;
    }

    if (
      this.parent
        .filter((v) => v !== value)
        .some((v) => v[propertyName] === value[propertyName])
    ) {
      throw this.createError({
        path: `${this.path}.${propertyName}`,
      });
    }

    return true;
  });
});

export default Yup;
import React                from "react";
import PropTypes            from "prop-types";
import {
  Text,
  View,
  StyleSheet,
  TextInput,
}                           from "react-native";
import { useFormikContext } from "formik";
import formStyles           from "../../styles/Form";
import Field                from "./Field";


LineItemInput.propTypes = {
  itemIndex: PropTypes.number.isRequired,
  groupIndex: PropTypes.number.isRequired,
  isSubmission: PropTypes.bool.isRequired,
  arrayHelpers: PropTypes.object.isRequired,
};

function LineItemInput(props) {
  const { itemIndex, groupIndex, isSubmission, arrayHelpers } = props;
  
  const {
    values,
    handleChange,
    handleBlur,
  } = useFormikContext();
  
  let itemIdentifier = `itemGroups.${groupIndex}.items.${itemIndex}`;
  if(!isSubmission) itemIdentifier = `workOrder.${itemIdentifier}`
  return (
    <View style={styles.row}>
      <View style={styles.id}>
        <Field
          field={`${itemIdentifier}.identifier`}
          itemIndex={itemIndex}
          arrayHelpers={arrayHelpers}
        />
      </View>
      <View style={styles.name}>
        <Field
          field={`${itemIdentifier}.name`}
          itemIndex={itemIndex}
          arrayHelpers={
            arrayHelpers
          }
        />
      </View>
      {isSubmission ? (
                      <View style={styles.actual}>
                        <Field
                          field={`${itemIdentifier}.actual`}
                          keyboardType="numeric"
                          itemIndex={itemIndex}
                          arrayHelpers={arrayHelpers}
                        />
                      </View>
                    )
                    :
       (
         <View style={styles.expected}>
           <Field
             field={`${itemIdentifier}.expected`}
             keyboardType="numeric"
             itemIndex={itemIndex}
             arrayHelpers={arrayHelpers}
           />
         </View>
       )
      }
      
      <View style={styles.unit}>
        <Field
          field={`${itemIdentifier}.unit`}
          itemIndex={itemIndex}
          arrayHelpers={arrayHelpers}
        />
      </View>
    </View>
  );
}

export default LineItemInput;

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
  },
  id: {
    flex: 1,
  },
  name: {
    flex: 4,
  },
  expected: {
    flex: 2,
  },
  actual: {
    flex: 2,
  },
  unit: {
    flex: 1,
  },
});
import { StyleSheet } from "react-native";

const stdShadow = {
  shadowColor: "#000",
  shadowOffset: {
    width: 2,
    height: 8,
  },
  shadowOpacity: 0.32,
  shadowRadius: 11.14,
  elevation: 10,
};
const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    flex: 1,
    flexDirection: "column",
  },
  hero: {
    fontSize: 24,
    fontWeight: "bold",
  },
  title: {
    fontSize: 24,
    // fontWeight: "bold",
  },
  row: {
    flexDirection: "row",
    padding: 10,
    flexWrap: "wrap",
    marginBottom: 10,
    alignItems: "baseline",
  },
  box: {
    marginRight: 10,
    flexWrap: "wrap",
    flex: 1,
    marginBottom: 8,
    // width: "48%",
    minWidth: 400,
    ...stdShadow,
    padding: 10,
    borderRadius: 5,
    backgroundColor: "white",
  },
  body: {
    fontSize: 17,
    flexWrap: "wrap",
    // borderWidth: 1,
    // flex: 1,
  },
  anchor: {
    fontSize: 17,
    color: "indigo",
    textDecorationLine: "underline",
  },
  label: {
    fontSize: 17,
    fontWeight: "bold",
  },
  divider: {
    borderWidth: 1,
    borderColor: "#999",
    marginHorizontal: 10,
  },
  table: {
    flexDirection: "column",
    width: "100%",
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-around",
    borderBottomWidth: 1,
    paddingBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
    justifyContent: "space-around",
    borderBottomWidth: 1,
    borderColor: "#eee",
  },
  tableCell: {
    flex: 1,
    alignItems: "center",
    padding: 5,
  },
  headerCell: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 5,
  },
  headerLabel: {
    flex: 1,
    fontSize: 18,
    fontWeight: "bold",
  },
  heroPhotoWrapper: {
    flex: 1,
    width: "100%",
    borderWidth: 0,
    borderColor: "#aaa",
    padding: 3,
    margin: 5,
    borderRadius: 3,
  },
  heroPhoto: {
    resizeMode: "contain",
    width: "100%",
    height: 500,
  },
});

export default styles;
/* eslint-disable no-nested-ternary */
import React                        from "react";
import { NavigationContainer }      from "@react-navigation/native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import WorkOrderTab               from "./WorkOrderTab";
import ClientTab                  from "./ClientTab";
import DetailsScreen from "../screens/DetailsScreen";
import SignInScreen                 from "../screens/SignInScreen";
import LoadingScreen                from "../screens/LoadingScreen";

import { UserContext } from "../providers/UserProvider";
import AppLinking from "./AppLinkConfig";
import UserTab    from "./UserTab";

const Tab = createBottomTabNavigator();

function Navigation() {
  return (
    <UserContext.Consumer>
      {({ user, profile, authStateFetched }) =>
        user || !authStateFetched ? (
          profile ? (
            <NavigationContainer linking={AppLinking}>
              <Tab.Navigator
                tabBarOptions={{
                  activeTintColor: "indigo",
                }}
                screenOptions={({ route }) => ({
                  // eslint-disable-next-line react/prop-types
                  tabBarIcon: ({ focused, color, size }) => {
                    let iconName;

                    switch (route.name) {
                      case "WorkOrders":
                        iconName = focused
                                   ? "view-dashboard"
                                   : "view-dashboard-outline";
                        break;
                      case "Jobs":
                        iconName = focused ? "briefcase" : "briefcase-outline";
                        break;
                      case "Clients":
                        iconName = focused ? "account" : "account-outline";
                        break;
                      case "Settings":
                        iconName = focused ? "cog" : "cog-outline";
                        break;
                      default:
                        iconName = focused ? "account" : "account-outline";
                        break;
                    }

                    return (
                      <MaterialCommunityIcons
                        name={iconName}
                        size={size}
                        color={color}
                      />
                    );
                  },
                })}
              >
                <Tab.Screen
                  name="WorkOrders"
                  component={WorkOrderTab}
                  options={{ title: "Work Orders" }}
                />
                <Tab.Screen
                  name="Clients"
                  component={ClientTab}
                  options={{ title: "Clients" }}
                />
                {profile.role !== "foreman" ?
                 <Tab.Screen
                   name="Users"
                   component={UserTab}
                   options={{ title: "Users" }}
                 />
                                            : null
                }
                <Tab.Screen
                  name="Settings"
                  component={DetailsScreen}
                  options={{ title: "Settings" }}
                />
              </Tab.Navigator>
            </NavigationContainer>
          ) : (
            <LoadingScreen />
          )
        ) : (
          <NavigationContainer>
            <Tab.Navigator initialRouteName="SignIn">
              <Tab.Screen
                name="SignIn"
                component={SignInScreen}
                options={{ title: "Sign In" }}
              />
            </Tab.Navigator>
          </NavigationContainer>
        )
      }
    </UserContext.Consumer>
  );
}

export default Navigation;

import React from 'react';
import { Text, View, Switch } from "react-native";
import { useFormikContext } from "formik";
import styles from '../../styles/Form'
import { WorkOrderFormValues } from "../../types/WorkOrder";

export default function JobToggle() {

  const { values, setFieldValue } = useFormikContext<WorkOrderFormValues>()
  const fieldKey = "job.new"
  return (
    <View style={[styles.row, { alignItems: "baseline"}]}>
      <Switch
        value={values.job.new}
        onValueChange={(val) => setFieldValue(fieldKey, val, true)}
      />
      <Text style={styles.title}>
        { values.job.new ? " Create New Job" : " Select Existing Job" }
      </Text>
    </View>
  )
}

import { Text, View } from "react-native";
import React                from "react";
// @ts-ignore
import DateTimePicker       from "react-datetime-picker";
import { useFormikContext } from "formik";
import PropTypes            from "prop-types";
import _ from "lodash";
import styles               from "../styles/Form";

export default function DateInput({ label = "Date", fieldKey }) {
  
  const { setFieldValue, values } = useFormikContext();

  const value = _.get(values, fieldKey)

  console.log('DATE INPUT: value - ', value)

  return (
    <View style={{ zIndex: 1 }}>
      <Text style={styles.label}>{label}</Text>
       <DateTimePicker
        zIndex={9999}
        onChange={(val) => setFieldValue(fieldKey, val)}
        value={value}
        // value={values[fieldKey] && values[fieldKey].toDate()}
       />
      {/* { createElement('input', { */}
      {/*  type: 'date', */}
      {/*  value, */}
      {/*  onInput: (val) => console.log('create element ', val), */}
      {/* })} */}
    </View>
  );
}

DateInput.propTypes = {
  label: PropTypes.string,
  fieldKey: PropTypes.string.isRequired,
};

DateInput.defaultProps = {
  label: "Date",
};
import React                     from "react";
import { createStackNavigator }  from "@react-navigation/stack";
import { Button }                from "react-native-elements";
import { Platform }             from "react-native";
import WorkOrderListScreen       from "../screens/WorkOrderListScreen";
import NewWorkOrderScreen        from "../screens/NewWorkOrderScreen";
import ShowWorkOrderScreen       from "../screens/ShowWorkOrderScreen";
import SubmissionsListScreen     from "../screens/SubmissionsListScreen";
import NewSubmissionScreen       from "../screens/NewSubmissionScreen";
import CompletedWorkOrdersScreen from "../screens/CompletedWorkOrdersScreen";
import CalendarScreen            from "../screens/CalendarScreen";
import EditWorkOrderScreen       from "../screens/EditWorkOrderScreen";

export type WorkOrderStackParamList = {
  List: undefined,
  Completed: undefined,
  New: undefined,
  Show: { workOrderID: string },
  Calendar: undefined,
  Edit: { workOrderID: string },
  Submissions: { workOrderID: string},
  NewSubmission: { workOrderID: string },
}


const Stack = createStackNavigator<WorkOrderStackParamList>();

function WorkOrderTab() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="List"
        component={WorkOrderListScreen}
        options={{
          title: "Work Orders",
        }}
      />
      <Stack.Screen
        name="Completed"
        component={CompletedWorkOrdersScreen}
        options={{ title: "Completed Work Orders" }}
      />
      <Stack.Screen
        name="Calendar"
        component={CalendarScreen}
        options={{ title: "Calendar" }}
      />
      <Stack.Screen
        name="New"
        component={NewWorkOrderScreen}
        options={{ title: "New Work Order" }}
      />
      <Stack.Screen
        name="Edit"
        component={EditWorkOrderScreen}
        options={{
          title: "Edit Work Order",
        }}
      />
      <Stack.Screen
        name="Show"
        component={ShowWorkOrderScreen}
        options={({ navigation, route}) => ({
          title: "Work Order",
          headerRight: () => {
            if( Platform.OS === "web") {
              return <Button
                title="Edit"
                type="clear"
                onPress={() => navigation.navigate("Edit", { workOrderID: route.params.workOrderID})}
              />
            }
          },
        })}
      />
      <Stack.Screen
        name="Submissions"
        component={SubmissionsListScreen}
        options={{ title: "Submissions" }}
      />
      <Stack.Screen
        name="NewSubmission"
        component={NewSubmissionScreen}
        options={{ title: "New Submission" }}
      />
    </Stack.Navigator>
  );
}

export default WorkOrderTab;

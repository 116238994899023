import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { Ionicons } from "@expo/vector-icons";

const fabDiameter = 55;

const styles = StyleSheet.create({
  fabContainer: {
    position: "absolute",
    // width: 50,
    // height: 50,
    alignItems: "center",
    justifyContent: "center",
    right: 30,
    bottom: 30,
  },
  fab: {
    backgroundColor: "indigo",
    // padding: 10,
    alignItems: "center",
    justifyContent: "center",
    width: fabDiameter,
    height: fabDiameter,
    borderRadius: fabDiameter / 2,
    elevation: 8,
    shadowColor: "black",
    shadowOffset: {
      width: 2,
      height: 4,
    },
    shadowRadius: 2.62,
    shadowOpacity: 0.4,
  },
});

export default function FloatingActionButton({ onPress }) {
  const icon = <Ionicons name="ios-add" size={32} color="white" />;

  return (
    <TouchableOpacity style={styles.fabContainer} onPress={onPress}>
      <View style={styles.fab}>{icon}</View>
    </TouchableOpacity>
  );
}

FloatingActionButton.propTypes = {
  onPress: PropTypes.func.isRequired,
};

import { StyleSheet } from "react-native";

const stdShadow = {
  shadowColor: "#000",
  shadowOffset: {
    width: 2,
    height: 4,
  },
  shadowOpacity: 0.4,
  shadowRadius: 2.62,
  elevation: 4,
};

const inputBorder = {
  borderWidth: 1,
  borderColor: "#ddd",
};

const stdInputGroup = {
  marginRight: 5,
  // borderWidth: 2,
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    // width: "100%",
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "flex-start",
    // marginBottom: 15,
    // paddingHorizontal: 15,
    // borderWidth: 1,
  },
  row: {
    // flex: 1,
    flexDirection: "row",
    alignItems: "baseline",
    justifyContent: "flex-start",
    marginHorizontal: 5,
    width: 800,
    // maxWidth: 800,
    // width: "100%",
    // minWidth: 400,
    // maxWidth: 800,
    // borderWidth: 3,
    // borderColor: "#f00",
  },
  column: {
    flexDirection: "column",
    flex: 1,
    // borderColor: 'green',
    // borderWidth: 2,
  },
  block: {
    marginHorizontal: 5,
    width: "90%",
    maxWidth: 800,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 15,
  },
  subhead: {
    fontSize: 20,
    color: "#333",
    marginBottom: 15,
  },

  error: {
    marginBottom: 20,
    paddingBottom: 10,
    height: 17.5,
    fontSize: 17,
    fontWeight: "500",
    color: "#d94723",
  },
  formContainer: {
    width: "100%",
    maxWidth: 1000,
    // borderColor: 'red',
    // borderWidth: 2,
    // flex: 1,
    // borderWidth: 1,
    paddingTop: 10,
    justifyContent: "flex-start",
    alignItems: "center",
  },
  inputShort: {
    minHeight: 40,
    // flex: 3,
    paddingHorizontal: 5,
    backgroundColor: "#fff",
    marginBottom: 20,
    ...stdShadow,
    ...inputBorder,
  },
  inputGroupShort: {
    ...stdInputGroup,
    flex: 1,
  },
  input: {
    minHeight: 40,
    // flex: 5,
    // flexGrow: 1,
    paddingHorizontal: 5,
    backgroundColor: "white",
    paddingVertical: 5,
    marginBottom: 20,
    ...stdShadow,
    ...inputBorder,
  },
  inputTall: {
    minHeight: 40,
    flexGrow: 1,
    backgroundColor: "white",
    paddingVertical: 5,
    paddingHorizontal: 5,
    marginBottom: 20,
    ...stdShadow,
    ...inputBorder,
  },
  inputGroup: {
    ...stdInputGroup,
    flex: 3,
  },

  label: {
    fontWeight: "600",
    fontSize: 17,
    textAlign: "left",
    color: "#555",
  },
  inputContainer: {},
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: "#888",
    // width: 400,
    maxWidth: 800,
    marginVertical: 10,
  },
});

export default styles;

import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import styles from "../styles/Card";
import Client from "../types/Client";

type Props = {
  document: Client
}

export default function ClientCard({ document }: Props) {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      onPress={() =>
        navigation.navigate("Show", { clientID: document.id })
      }
    >
      <View style={styles.card}>
        <Text style={styles.cardText}>{document.name}</Text>
      </View>
    </TouchableOpacity>
  );
}


import React from "react";
import { FlatList, Text, View } from "react-native";
import { useCollection } from "@nandorojo/swr-firestore";
import { useNavigation } from '@react-navigation/native'
import ClientCard from "../components/ClientCard";
import { UserContext } from "../providers/UserProvider";
import styles from "../styles/List";
import Client from "../types/Client";
import { assertIsProfile } from "../services/ProfileService";
import FloatingActionButton from "../components/FloatingActionButton";

export default function ClientListScreen() {
  const { profile } = React.useContext(UserContext);
  const navigation = useNavigation()
  assertIsProfile(profile);

  const { data, error } = useCollection<Client>(`${profile.company.path}/clients`, { listen: true });

  if (error) return (<View><Text>An error occurred fetching client list.</Text></View>);
  return (
    <View style={styles.container}>
      <Text style={styles.title}>All Clients</Text>
      { data ?
        <FlatList
          data={data}
          renderItem={({ item }) => <ClientCard document={item} />}
          keyExtractor={(item) => item.id}
        />
        : <Text>Fetching clients...</Text>
      }
      <FloatingActionButton onPress={() => navigation.navigate('New')}/>
    </View>
  );
}

import React                                                         from "react";
import { View, Text, StyleSheet, Button, TouchableOpacity, Linking } from "react-native";
import { fuego }                                                     from "@nandorojo/swr-firestore";
import { auth }                                                      from "../config/fb";
import { UserContext }                                               from "../providers/UserProvider";

const appjson = require('../app.json');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  body: {
    fontSize: 17,
    marginVertical: 5,
  },
  
});

function DetailsScreen() {
  
  const { profile } = React.useContext(UserContext)
  
  return (
    <View style={styles.container}>
      <Text style={styles.body}>
        Signed in as: {profile.name} ({profile.role})
      </Text>
      
      
      <Button onPress={() => fuego.auth().signOut()} title="Sign Out" />
  
      <Text style={{ marginTop: 15, fontWeight: 'bold', fontSize: 20 }}>Need help?</Text>
      <TouchableOpacity
        onPress={() => Linking.openURL('mailto:nick@blankcanvas.tech?subject=[Ground%20Control]%20Help:%20')}
      >
        <Text style={[styles.body, { color: 'indigo'}]}>
          Email nick@blankcanvas.tech
          
        </Text>
      </TouchableOpacity>
      <Text style={styles.body}>Or, take a screenshot and upload to TestFlight</Text>
      <Text style={styles.body}>For emergencies, call Nick at (321)332-2601</Text>
      <Text style={{ fontWeight: 'bold', fontSize: 17, marginTop: 20}}>Version Information</Text>
      <Text style={styles.body}>You&apos;re on version: {appjson.expo.version} build #{appjson.expo.ios.buildNumber}</Text>
      { profile.id === "ZkzlnE1y12b6RxdZmA3yZ0sGzcP2" ? <DevMigrations /> : null}
    </View>
  );
}

export default DetailsScreen;

function DevMigrations() {
  const { profile } = React.useContext(UserContext)
  
  
  return (
    <>
      <Text style={{ fontWeight: 'bold', fontSize: 17, marginTop: 20 }}>
        Developer Migrations
      </Text>
      <Button
        title="Add archived flag to Work Orders"
        onPress={() => profile.company.collection('work_orders').get().then((collection) => {
          collection.forEach(qds => {
            if(typeof qds.data().archived === 'undefined') {
              console.log('updating work order...')
              qds.ref.update({ archived: false })
            }
          })
          console.log('done with migration')
        })}
        />
    </>
  )
}

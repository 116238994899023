import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
// eslint-disable-next-line import/no-cycle
import ClientListScreen from "../screens/ClientListScreen";
import NewClientScreen from "../screens/NewClientScreen";
import ShowClientScreen from "../screens/ShowClientScreen";

export type ClientStackParamList = {
  List: undefined,
  New: undefined,
  Show: { clientID: string }
}

const Stack = createStackNavigator<ClientStackParamList>();

export default function ClientTab() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="List"
        component={ClientListScreen}
        options={{
          title: "Clients",
        }}
      />
      <Stack.Screen
        name="New"
        component={NewClientScreen}
        options={{ title: "New Client" }}
      />
      <Stack.Screen
        name="Show"
        component={ShowClientScreen}
        options={{ title: "Client" }}
      />
    </Stack.Navigator>
  );
}

import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, TouchableOpacity, View } from "react-native";

const styles = StyleSheet.create({
  button: {
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "baseline",
    padding: 10,
    borderRadius: 5,
    // borderWidth: 2,
    elevation: 10,
    shadowOpacity: 0.4,
    shadowOffset: {
      vertical: 5,
      horizontal: 5,
    },
  },
});

export default function RaisedButon({ color, children, onPress }) {
  return (
    <TouchableOpacity
      style={[styles.button, { backgroundColor: "indigo" }]}
      onPress={onPress}
    >
      {children}
    </TouchableOpacity>
  );
}

RaisedButon.defaultProps = {
  color: "indigo",
};

RaisedButon.propTypes = {
  color: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onPress: PropTypes.func.isRequired,
};

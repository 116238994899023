import React                     from "react";
import PropTypes                 from "prop-types";
import { TextInput, StyleSheet }      from "react-native";
import { useField, useFormikContext } from "formik";
import _                              from 'lodash'
import formStyles          from "../../styles/Form";
import { handleItemPaste } from "../../services/WorkOrderFormService";

Field.propTypes = {
  field: PropTypes.string.isRequired,
  keyboardType: PropTypes.string,
  itemIndex: PropTypes.number.isRequired,
  arrayHelpers: PropTypes.object.isRequired,
};

Field.defaultProps = {
  keyboardType: 'default',
}

function Field({ field, keyboardType, itemIndex, arrayHelpers }) {
  
  const [formikField, meta, helpers] = useField(field)
  
  const handleChangeText = (value) => {
    console.log(`text change - ${ 
      value}`)
    if(value.indexOf('\t') === -1) {
      helpers.setValue(value)
      helpers.setTouched(true, false)
    } else {
      handleItemPaste({ value, index: itemIndex, arrayHelpers})
    }
  }
  
  const debouncedHandleChangeText = _.debounce(handleChangeText, 250)
  return (
    <TextInput
      style={[formStyles.input, styles.input]}
      defaultValue={formikField.value.toString()}
      onChangeText={debouncedHandleChangeText}
      keyboardType={keyboardType}
      multiline
    />
  );
}

export default Field;

const styles = StyleSheet.create({
  input: {
    width:'100%',
    marginBottom: 0,
    textAlignVertical: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 10,
  },
})

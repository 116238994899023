import React                                from "react";
import { View, Text, StyleSheet, FlatList } from "react-native";
import { FontAwesome }                from "@expo/vector-icons";
import TextInput                      from "react-native-web/dist/exports/TextInput";
import { Card }                       from "react-native-elements";
import { useCollection }              from "@nandorojo/swr-firestore";
import FloatingActionButton           from "../components/FloatingActionButton";
import styles                         from "../styles/List";
import { streamWorkOrderSubmissions } from "../services/WorkOrderService";
import { UserContext }                from "../providers/UserProvider";
import OutlineButton                  from "../components/OutlineButton";
import RaisedButton                   from "../components/RaisedButton";
import ItemGroupTables                from "../components/ItemGroupTables";
import { assertIsProfile }            from "../services/ProfileService";

const table = StyleSheet.create({
  table: {
    flexDirection: "column",
    width: 780,
    borderColor: '#efefef',
    borderRadius: 2,
    borderWidth: 1,
    marginBottom: 5,
    padding: 5,
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-around",
    borderBottomWidth: 1,
    paddingBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
    justifyContent: "space-around",
    borderBottomWidth: 1,
    borderColor: "#eee",
  },
  tableCell: {
    flex: 1,
    alignItems: "center",
    padding: 5,
  },
  headerCell: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 5,
  },
  headerLabel: {
    flex: 1,
    fontSize: 18,
    fontWeight: "bold",
  },
})

export default function SubmissionsListScreen({ navigation, route }) {
  const onPress = () => navigation.navigate("NewSubmission", { workOrderID: route.params.workOrderID});
  // const [submissions, setSubmissions] = React.useState([])
  const { profile } = React.useContext(UserContext)
  assertIsProfile(profile)
  const { data, error, loading } = useCollection(
    `${profile.company.path}/work_orders/${route.params.workOrderID}/submissions`,
    { listen: true, parseDates: ['submittedAt'] },
    )
  // React.useEffect(() => {
  //   streamWorkOrderSubmissions({ profile, workOrderID: route.params.workOrderID, setSubmissions })
  // })
  if(error) return (
    <View style={styles.container}>
      <Text style={styles.body}>
        An error occurred fetching submissions: {error}
      </Text>
    </View>
  )
  
  if(loading) return (
    <View style={styles.container}>
      <Text style={styles.body}>Fetching submissions...</Text>
    </View>
  )
  
  
  if(!data) return (
    <View style={styles.container}>
      <Text style={styles.body}>
        No submissions recorded for this Work Order.
      </Text>
    </View>
  )
  
  const renderSubmission = ({item}) => (
    <Card wrapperStyle={{ width: 800}}>

      <ItemGroupTables itemGroups={item.itemGroups} />
      <View style={styles.row}>
        <Text>Status: {item.status}</Text>
        { item.submittedAt ? (
          <Text>Submitted: {item.submittedAt.toLocaleString()}</Text>
        ) : null}
      </View>
    </Card>
  );
  
  return (
    <View style={styles.container}>
      <Text style={styles.title}>List Submissions</Text>
      <FlatList data={data} renderItem={renderSubmission} />
      <FloatingActionButton onPress={onPress} />
    </View>
  );
}

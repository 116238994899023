import React, { useContext } from "react";
import { View, Text } from "react-native";
import { useCollection } from "@nandorojo/swr-firestore";
import styles from '../../styles/Form'
import Label from "./Label";
import { UserContext } from "../../providers/UserProvider";
import { assertIsProfile } from "../../services/ProfileService";
import Picker from "./Picker";
import TextField from "./TextField";
import Client from "../../types/Client";

export default function NewJobFields() {
  const { profile } = useContext(UserContext)
  assertIsProfile(profile)
  const { data, add } = useCollection<Client>(
    `${profile.company.path}/clients`,
    { listen: true },
    )
  return (
    <>
      <View style={styles.row}>
        <View style={styles.column}>
          <Label text="Client"/>
          { data ? (<Picker
            data={data}
            field="job.clientID"
          />) : null }
        </View>
      </View>
      <View style={styles.row}>
        <TextField
          field="job.identifier"
          label="Identifier"
          flex={2}
        />
        <TextField
          field="job.name"
          label="Name"
          flex={6}
        />
      </View>
      </>
  )
}